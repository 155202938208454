import React from "react";
import { useEffect } from "react";

import Section_initial from "./section_initial";
import Section_0 from "./section_0";
import Section_1 from "./section_1";
import Section_2 from "./section_2";
import Section_3 from "./section_3";
import Section_4 from "./section_4";
import Section_5 from "./section_5";
// import Section_6 from "./section_6";
// import Section_7 from "./section_7";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Section_initial />
      <Section_0 />
      <Section_1 />
      <Section_2 />
      <Section_3 />
      <Section_4 />
      <Section_5 />
      {/* <Section_6/> */}
      {/* <Section_7/> */}
    </>
  );
}

export default Home;
