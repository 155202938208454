// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// // import e1 from "../../../assets/svg/e1.svg";
// // import e2 from "../../../assets/svg/e2.svg";
// // import e3 from "../../../assets/svg/e3.svg";
// // import e4 from "../../../assets/svg/e4.svg";
// // import star_img from "../../../assets/svg/star_img.svg";
// // import { Link } from "react-router-dom";
// import map_img from "../../../assets/svg/map_img.svg";
// import cal_img from "../../../assets/svg/cal_img.svg";
// const Section_4 = () => {
//   const [cardData, setCardData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate(); // Initialize navigate hook

//   // Fetch data on component mount
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.post(
//           "https://backoffice.innerpece.com/api/get-program",
//           {
//             program_type: "popular_program",
//             view_type: "",
//           }
//         );
//         setCardData(response.data.data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleClick = (id) => {
//     // alert(id);
//     // Perform navigation
//     navigate('/sample', {state:{id}}); // Adjust URL based on your route configuration
//   };

//   return (
//     <div className="container-fluid paddings">
//       <div className="d-flex justify-content-between">
//         <div className="col-12">
//           <h1 className="loc_title">
//             Explore{" "}
//             <span className="Upcoming_Explore123">Popular Programs</span>
//           </h1>
//         </div>
//         <div className="col-3 text-end mt-4">
//           <h5 className="view_all71 viewallpage">
//             <a href="/popularevents">View All</a>
//           </h5>
//         </div>
//       </div>

//       <div className="row">
//         {/* Display loading or error message */}
//         {loading && <p>Loading...</p>}
//         {error && <p>Error: {error}</p>}

//         {/* Loop over cardData */}
//         {cardData.length > 0 ? (
//           cardData.map((card, index) => (
//             <div key={index} className="col-md-3">
//               <div
//                 className="card shadow-lg"
//                 onClick={() => handleClick(card.id)} // Call handleClick on click
//                 style={{ cursor: "pointer" }} // Change cursor to pointer
//               >
//                 <div className="card shadow-lg">
//                   <img
//                     className="card-img-top c_img"
//                     src={`https://backoffice.innerpece.com/${card.cover_img}`}
//                     alt="Card image"
//                   />
//                   <div className="card-body">
//                     <h4 className="card-title c1 ms-2">{card.title}</h4>
//                     <div className="card-text c2">
//                       <img className="map_img ms-1" src={map_img} alt="Card" />
//                       <span className="p-1">{card.location}</span>
//                     </div>
//                     <hr style={{ width: "50%" }} />
//                     <div className="card-text c2">
//                       <img className="cal_img ms-2" src={cal_img} alt="Card" />
//                       <span className="p-1 ms-1">{card.start_date}</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <p>No popular events available.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Section_4;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import map_img from "../../../assets/svg/map_img.svg";
import cal_img from "../../../assets/svg/cal_img.svg";
import defaultimg from "../../../assets/img/defaultimg.png";
const Section_4 = () => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate hook

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://backoffice.innerpece.com/api/get-program",
          {
            program_type: "popular_program",
            view_type: "",
          }
        );
        setCardData(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClick = (id, title) => {
    // Sanitize the title for the URL
    const sanitizedTitle = title
      .toLowerCase()
      .replace(/[^a-z0-9_]+/g, "-") // Remove all special characters except underscores
      .replace(/_+/g, "-") // Replace multiple underscores with a single underscore
      .replace(/^_+|_+$/g, "");

    // Perform navigation with dynamic title in the URL
    navigate(`/${id}/${sanitizedTitle}`, { state: { id, title } });
  };

  return (
    <div className="container-fluid paddings">
      <div className="d-flex justify-content-between">
        <div className="col-12">
          <h1 className="loc_title">
            Explore{" "}
            <span className="Upcoming_Explore123">Popular Programs</span>
          </h1>
        </div>
        <div className="col-3 text-end mt-4">
          <h5 className="view_all71 viewallpage">
            <a href="/popularevents">View All</a>
          </h5>
        </div>
      </div>

      <div className="row">
        {/* Display loading or error message */}
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}

        {/* Loop over cardData */}
        {cardData.length > 0 ? (
          cardData.map((card, index) => (
            <div key={index} className="col-lg-3 col-md-6 mb-4 mmb-30">
              <div
                className="card shadow-lg"
                onClick={() => handleClick(card.id, card.title)} // Pass id and title to handleClick
                style={{ cursor: "pointer" }} // Change cursor to pointer
              >
                <div className="card shadow-lg">
                  <img
                    className="card-img-top c_img1"
                    src={
                      card.cover_img
                        ? `https://backoffice.innerpece.com/${card.cover_img}`
                        : defaultimg
                    }
                    alt="Card image"
                  />
                  <div className="card-body">
                    <h4 className=" c1 ms-2">{card.title}</h4>
                    <div className=" c2">
                      <img className="map_img ms-1" src={map_img} alt="Card" />
                      <span className="p-1">{card.location}</span>
                    </div>
                    <hr style={{ width: "50%" }} />
                    <div className="card-text c2">
                      <img className="cal_img ms-2" src={cal_img} alt="Card" />
                      <span className="p-1 ms-1">{card.start_date}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No popular events available.</p>
        )}
      </div>
    </div>
  );
};

export default Section_4;
