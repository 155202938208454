import React from 'react';
import '../../../assets/css/sample/sample.css'
// import Sec1 from '../sample/section1.js'
// import Sec2 from '../sample/section2.js'
// import Sec3 from '../sample/section3.js'
// import Sec4 from '../sample/section4.js'
// import Sec5 from '../sample/section5.js'
// import Sec6 from '../sample/section6.js'
// import Sec7 from '../sample/section7.js'
import Sec8 from '../sample/example.js'


function Sample() {
  return (
    <>
      {/* <Sec1/>
      <Sec2/>
      <Sec3/>
      <Sec4/>
      <Sec5/>
      <Sec6/>
      <Sec7/> */}
      <Sec8/>
      
    </>
  );
}

export default Sample;