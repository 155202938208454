import React from 'react';
import "../../../assets/css/Thanks/thanks.css";
import img1 from "../../../assets/img/thanks/thanks.svg"
// import { Link } from "react-router-dom";



function Thanks() {
  return (
    <div className='nn'>
        <div class="message-container">
             <img src={img1} alt="Success Icon"/>
              <h1>We have received your request successfully.</h1>
              <p>Our support team will get back to you within the next 24 hours.</p>
              <h6 class="contact-info">For immediate assistance, please call us at <a href="tel:+91-345654322">+91-345654322</a> or email us at <a href="mailto:info@innerpece.com">info@innerpece.com</a></h6>
        </div>
   </div>
  
  );
}

export default Thanks;