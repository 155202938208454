//  import Exblogs from './views/components/exBlogs';

import Pages from "./views/components/Pages/Villa";
//  import Community from './views/components/Community';

// src/App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./views/header/Header";
import Footer from "./views/footer/footer";
import Home from "./views/components/Home";
import About from "./views/components/About";
// import Blogs from "./views/components/Blogs";
import Destinations from "./views/components/Destination";
import Contactus from "./views/components/Contact_us/index";
import Userlogin from "./views/components/User_login/userlogin.js";
import Usersignup from "./views/components/UserSign_up/usersignup.js";

// import BookEvent from "./views/components/Book_an_event";
import WesternGhats from "./views/components/Pages/WesternGhats";
import Himalayas from "./views/components/Pages/Himalayas";
import Choladynesty from "./views/components/Pages/Choladynesty";
import Chennai from "./views/components/Pages/Chennai";
import Coimbatore from "./views/components/Pages/Coimbatore";
import PopularEvent from "./views/components/Events/Popularevent";
import PopularDestination from "./views/components/Events/popular_destination.js";
import UpcomingEvent from "./views/components/Events/Upcomingevent";
import Sample from "./views/components/sample";
import Detail from "./views/components/Detail/index.js";
import Groupbooking from "./views/components/Groupbooking/groupbooking.js";
import Packages from "./views/components/Packagebooking/Packages";
import EventBooking from "./views/components/EventBooking/eventbooking.js";
// import PackageBooking from './views/components/PackageBooking/packagebooking.js';
// import Sample1 from './views/components/sample1/sample1';
import Podcasts from "./views/components/Podcasts/Podcasts";
//  import Test from "./views/components/test.js";
import Thanks from "./views/components/Thanks/index.js";
import ScrollToTop from "./views/components/ScrollToTop/ScrollToTop.js";
import Privacypolicy from "./views/components/Privacypolicy/privacy_policy.js";
import Termsandcondition from "./views/components/Termsandcondition/terms_and_condition.js";
import Termsandservice from "./views/components/Termsandservice/Terms_of_service.js";

function App() {
  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/submit" element={<Thanks />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/Blogs" element={<Blogs />} /> */}
        <Route path="/destinations" element={<Destinations />} />

        <Route path="/contact" element={<Contactus />} />
        <Route path="/userlogin" element={<Userlogin />} />
        <Route path="/usersignup" element={<Usersignup />} />

        {/* <Route path="/book-event" element={<BookEvent />} /> */}
        <Route path="/westernghats" element={<WesternGhats />} />
        <Route path="/himalayas" element={<Himalayas />} />
        <Route path="/choladynesty" element={<Choladynesty />} />
        {/* <Route path="/community" element={<Detail />} />  */}
        <Route path="/Chennai" element={<Chennai />} />
        <Route path="/Coimbatore" element={<Coimbatore />} />
        <Route path="/programs/:theme_name" element={<PopularEvent />} />
        <Route
          path="/destination/:city_name"
          element={<PopularDestination />}
        />
        <Route path="/upcomingevents" element={<UpcomingEvent />} />
        <Route path="/:id/:title" element={<Sample />} />

        {/* <Route path="/sample1" element={<Sample1 />} /> */}
        <Route path="/villas" element={<Pages />} />
        <Route path="/Podcasts" element={<Podcasts />} />
        <Route path="/detail" element={<Detail />} />
        <Route path="/groupbooking" element={<Groupbooking />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/eventbooking" element={<EventBooking />} />

        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsandcondition" element={<Termsandcondition />} />
        <Route path="/termsandservice" element={<Termsandservice />} />
        {/* <Route path="/packagebooking" element={<PackageBooking />} />  */}

        {/* <Route path="/test" element={<Test />} />  */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
