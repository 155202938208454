import React from "react";
// import d1 from "../../../assets/svg/d1.svg";
// import d2 from "../../../assets/svg/d2.svg";
// import d3 from "../../../assets/svg/d3.svg";
// import d4 from "../../../assets/svg/d4.svg";

// import d5 from "../../../assets/svg/d5.svg";
// import d6 from "../../../assets/svg/d6.svg";
// import d7 from "../../../assets/svg/d7.svg";
// import d8 from "../../../assets/svg/d8.svg";
import destination_2 from "../../../assets/svg/ropo_1.png";
// import destination_3 from "../../../assets/svg/destination_3.svg";
// import map_img from "../../../assets/svg/map_img.svg";
// import star_img from "../../../assets/svg/star_img.svg";
import { Link } from "react-router-dom";

const Section_5 = () => {
  // const cardData = [
  //   {
  //     title: "Tamilnadu",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  //   {
  //     title: "Kerala",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  //   {
  //     title: "Himachal Pradesh",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  //   {
  //     title: "Uttarakhand",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  // ];

  // const cardData2 = [
  //   {
  //     title: "Ladakh",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  //   {
  //     title: "Diving in Amed",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  //   {
  //     title: "Delhi",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  //   {
  //     title: "Oty",
  //     text: "Gianyar, Bali",
  //     text2: "4.2",
  //     text3: "(230 Reviews)",
  //   },
  // ];

  // const imagePaths = [d1, d2, d3, d4];
  // const imagePaths2 = [d5, d6, d7, d8];

  return (
    <div className=" container-fluid paddings ">
      {/* <div className="d-flex justify-content-between">
        <div className="col-11">
          <h1 className="loc_title">Destination</h1>
        </div>
        <div className="col-1">
          <h5 className="view_all71 text-end mt-4">
            <a href="http://example.com">View All</a>
          </h5>
        </div>
      </div>

      <div className="row mb-5">
      
        {cardData.map((card, index) => (
          <div key={index} className="col-md-3">
            <div className="card shadow-lg">
              <img
                className="card-img-top c_img"
                src={imagePaths[index % imagePaths.length]}
                alt="Card imageh"
              />
              <div className="card-body">
                <h4 className="card-title c1 ms-2">{card.title}</h4>
                <div className="card-text c2 ">
                  <span className="p-1 ms-1">{card.text}</span>
                </div>
                <div className="card-text c2 mt-1">
                  <img
                    className="star_img ms-1"
                    src={star_img}
                    alt="Card imagek"
                  />
                  <span className="p-1 rate_txt me-3">{card.text2}</span>
                  <span className="p-1">{card.text3}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}

      {/* <div className="row mt-5 mb-5">
       
        {cardData2.map((card, index) => (
          <div key={index} className="col-md-3">
            <div className="card">
             
              <img
                className="card-img-top c_img"
                src={imagePaths2[index % imagePaths2.length]}
                alt="Card imageh"
              />
              <div className="card-body">
                <h4 className="card-title c1 ms-2">{card.title}</h4>
                <div className="card-text c2 ">
                  <span className="p-1 ms-1">{card.text}</span>
                </div>
                <div className="card-text c2 mt-1">
                  <img className="star_img ms-1" src={star_img} alt="Card imagek" />
                  <span className="p-1 rate_txt me-3">{card.text2}</span>
                  <span className="p-1">{card.text3}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}
      <div className="container-fluid destinationimg mb-5 mt-4">
        <div className="row">
          <div className="col-md-1 mt-4 text-center">
            <div>
              <img src={destination_2} alt="img" className="img-fluid" />
            </div>
            {/* <div>
            <img src={destination_3} alt="img" className="img-fluid"/>
          </div> */}
          </div>
          <div className="col-md-11 mt-4 d-flex flex-column flex-md-row align-items-start">
            <div>
              <div className="destinationimg1 ready_text">
                Ready to adventure and enjoy natural
              </div>
              <p className="destinationimg3">
              Access your travel plans by logging in here!
              </p>
            </div>
            <div className=" me-5 mt-3">
            <Link to="/userlogin">
              <button
                type="button"
                className="mobile-btn destinationimg2 btn btn-light btn-lg "
              >
                LET'S GET STARTED
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_5;
