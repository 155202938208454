import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../assets/css/Groupbooking/groupbooking.css";
import destination_1 from "../../../assets/img/GroupBoking/Group_booking_1.svg";
import { Link } from "react-router-dom";

import destination_2 from "../../../assets/svg/ropo_1.png";
// import destination_2 from "../../../assets/svg/destination_2.svg";
// import destination_3 from "../../../assets/svg/destination_3.svg";

const CorporateTour = () => {
  const [groupBookings, setGroupBookings] = useState([]);

  useEffect(() => {
    axios
      .get("https://backoffice.innerpece.com/api/group-booking")

      .then((response) => {
        console.log("Group bookings:", response.data);
        if (response.data && response.data.group_booking) {
          setGroupBookings(response.data.group_booking);
        }
        console.log(response.data.group_booking);
      })

      .catch((error) => {
        console.error("Error fetching group bookings:", error);
      });
  }, []);

  return (
    <div className="corporate-tour-page">
      {/* Header Section */}
      <div className="header-section_67 position-relative">
        {groupBookings.length > 0 && (
          <img
            src={destination_1}
            alt="Group Tour Background"
            className="img-fluid w-100"
          />
        )}
        <div className="overlay_09 position-absolute top-50 start-50 translate-middle text-white text-center">
          <h1 className="display-4 main_group_1">Group Tour Packages</h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="content-section_09 py-2 text-center group_1">
        <h2 className="fw-bold mt-4">Explore our</h2>
        <p className="lead mt-4">
          Our Group tour packages offer services to all the corporates, to visit
          and enjoy all the best locations whether domestic or international.
        </p>
      </div>

      {/* Tour Package Details */}
      {groupBookings.map((booking) => (
        <div className="tour-package-section py-1" key={booking.id}>
          <div className="container-fluid group_content_1">
            <h2 className="fw-bold text-center mb-5">{booking.tour_title}</h2>
            <div className="row align-items-center">
              <div className="col-md-6">
                <h3>{booking.tour_title}</h3>
                <p>Tour Code: {booking.tour_code}</p>
                <h5 className="mb-5">{booking.tour_location}</h5>
                <p>Description</p>
                <p className="mt-3">{booking.tour_desc}</p>
                <button className="mb-4">Book Now</button>
              </div>
              <div className="col-md-6 text-end">
                <div className="col-12">
                  <img
                    src={`https://backoffice.innerpece.com/${booking.group_tour_img}`}
                    alt={booking.tour_title}
                    className="img-fluid rounded"
                    width="80%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Additional Content */}
      <div className="container-fluid destinationimg mb-5 mt-5">
        <div className="row">
          <div className="col-md-1 mt-4 text-center">
            <div>
              <img src={destination_2} alt="img" className="img-fluid" />
            </div>
            {/* <div>
            <img src={destination_3} alt="img" className="img-fluid"/>
          </div> */}
          </div>
          <div className="col-md-11 mt-4 d-flex flex-column flex-md-row align-items-start">
            <div>
              <div className="destinationimg1 ready_text">
                Ready to adventure and enjoy natural
              </div>
              <p className="destinationimg3">
              Access your travel plans by logging in here!
              </p>
            </div>
            <div className="destinationimg2 me-5 mt-3">
            <Link to="/userlogin">
              <button
                type="button"
                className="mobile-btn btn btn-light btn-lg "
              >
                LET'S GET STARTED
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateTour;

/* <div className="tour-package-section py-5 mt-5">
        <div className="container-fluid group_content_1">
          <h2 className="fw-bold text-center mb-5">Group Tour Packages</h2>
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3>Group Tour - 4</h3>
              <p className="text-muted mt-4">Tour Code: PTT077</p>
              <h5 className="mb-4">Chennai – Kodaikanal – Chennai</h5>
              <p>Description</p>
              <p className="mt-3">
                Group Tour Packages covers 2 days tour in the following places
                visiting like Chennai – Kodaikanal – Chennai. This Package is an
                affordable price.
              </p>
              <button className="">Book Now</button>
            </div>
            <div className="col-md-6">
              <img
                src={Group_booking_4}
                alt="Corporate Tour"
                className="img-fluid rounded"
                width="80%"
              />
            </div>
          </div>
        </div>
      </div> */

/* <div className="tour-package-section  tour-package_1 py-5">
        <div className="container-fluid group_content_1">
          <h2 className="fw-bold text-center mb-5">Group Tour Packages</h2>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src={Group_booking_5}
                alt="Corporate Tour"
                className="img-fluid rounded"
                width="80%"
              />
            </div>
            <div className="col-md-6">
              <h3>Group Tour - 5</h3>
              <p className="text-muted mt-4">Tour Code: PTT077</p>
              <h5 className="mb-4">Chennai – Kodaikanal – Chennai</h5>
              <p>Description</p>
              <p className="mt-3">
                Group Tour Packages covers 2 days tour in the following places
                visiting like Chennai – Kodaikanal – Chennai. This Package is an
                affordable price.
              </p>
              <button className="">Book Now</button>
            </div>
          </div>
        </div>
      </div> */
//     </div>
//   );
// };

// export default CorporateTour;
