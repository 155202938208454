import React from "react";
import e1 from "../../../assets/svg/e1.svg";
import e2 from "../../../assets/svg/e2.svg";
import e3 from "../../../assets/svg/e3.svg";
import e4 from "../../../assets/svg/e4.svg";

import e5 from "../../../assets/svg/e5.svg";
import e6 from "../../../assets/svg/e6.svg";
import e7 from "../../../assets/svg/e7.svg";
import e8 from "../../../assets/svg/e8.svg";

import e9 from "../../../assets/svg/e9.svg";
import e10 from "../../../assets/svg/e10.svg";
import e11 from "../../../assets/svg/e11.svg";
import e12 from "../../../assets/svg/e12.svg";

import e13 from "../../../assets/svg/e13.svg";
import e14 from "../../../assets/svg/e14.svg";
import e15 from "../../../assets/svg/e15.svg";
// import e16 from "../../../assets/svg/e16.svg";

import "../../../assets/css/Pages/Chennai.css";
import star_img from "../../../assets/svg/star_img.svg";
import Location from "../../../assets/img/Events/location.img.svg";
import Person from "../../../assets/img/Events/person_img.svg";
import Days from "../../../assets/img/Events/days_img.svg";
import camera from "../../../assets/img/Chennai/Camara_che.img.svg";
import player from "../../../assets/img/Chennai/Video_che.img.svg";
const chennai = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const ratingStars = document.querySelectorAll('.che_8 input[type="radio"]');
    ratingStars.forEach((star) => {
      star.addEventListener("change", (event) => {
        console.log(`Rated ${event.target.value} stars`);
      });
    });
  });
  const cardData = [
    {
      text: "Gianyar, Bali",
      title: "Diving in Amed Area by Usam",
      text2: "4.2",
      text3: "(1 Review)",
      title1: "Days and 6 Nignts From Moscow",
      rating: 5,
    },
    {
      text: "Gianyar, Bali",
      title: "Diving in Amed Area by Usam",
      text2: "4.2",
      rating: 5,
      text3: "(1 Review)",
      title1: "Days and 6 Nignts From Moscow",
    },
    {
      text: "Gianyar, Bali",
      title: "Diving in Amed Area by Usam",
      text2: "4.2",
      rating: 5,
      text3: "(1 Review)",
      title1: "Days and 6 Nignts From Moscow",
    },
    {
      text: "Gianyar, Bali",
      title: "Diving in Amed Area by Usam",
      text2: "4.2",
      rating: 5,
      text3: "(1 Review)",
      title1: "Days and 6 Nignts From Moscow",
    },
  ];

  const imagePaths = [e1, e2, e3, e4];
  // const imagePaths2 = [e5, e6, e7, e8];
  const imagePaths3 = [e9, e10, e11, e12];
  // const imagePaths4 = [e13, e14, e15, e10];
  return (
    <div className="section3">
      <div className="sec_3_color">
      <div className="container-fluid d-grid mt-5">
        <h1 className="che_1 mt-5">Events in Chennai</h1>
        <div className="row">
          {/* Loop over cardData */}
          {cardData.map((card, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card card shadow  mb-5 bg-body rounded">
                {/* Use corresponding image from imagePaths */}
                <img
                  className="card-img-top che_2 c_img"
                  src={imagePaths[index % imagePaths.length]}
                  alt="Card image "
                />
                <div className="card-body">
                  <h4 className="card-title che_3 ms-2">{card.title}</h4>
                  <div className="card-text che_4 ms-1">
                    <span className="p-1">{card.text}</span>
                  </div>
                  <div className="card-text che_4 mt-1">
                    <img className="che_5 ms-1" src={star_img} alt="Star image" />
                    <span className="p-1 che_6">{card.text2}</span>
                    <span className="p-1 tex_3">{card.text3}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>



        <div className="row">
         
          {/* {cardData.map((card, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card card shadow  mb-5 bg-body rounded">
               
                <img
                  className="card-img-top che_2 c_img"
                  src={imagePaths2[index % imagePaths2.length]}
                  alt="Card image"
                />
                <div className="card-body">
                  <h4 className="card-title che_3 ms-2">{card.title}</h4>
                  <div className="card-text che_4 ms-1">
                    <span className="p-1">{card.text}</span>
                  </div>
                  <div className="card-text che_4 mt-1">
                    <img className="che_5 ms-1" src={star_img} alt="Star image" />
                    <span className="p-1 che_6">{card.text2}</span>
                    <span className="p-1">{card.text3}</span>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>






        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <button className="btn che_7" type="submit">
              View all
            </button>
          </div>
        </div>

        <h1 className="che_1 mb-3">Packages in Chennai</h1>

        <div className="row">
          {/* Loop over cardData */}
          {cardData.map((card, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card border-0 shadow  mb-5 bg-body rounded">
                {/* Use corresponding image from imagePaths */}
                <div className="row imagebody" id="img_hov">
                <img
                  className="card-img-top border che_2 c_img"
                  src={imagePaths3[index % imagePaths3.length]}
                  alt="Card image"
                />
               
                  <div className="col-6">
                    <p className="Featured">Featured</p>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <p className="imagebodys ms-5">
                          <img src={camera}></img>5
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="imagebodys ms-4">
                          <img src={player}></img>2
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body border-0">
                  <div className="card-text che_4 mb-3">
                    <img
                      src={Location}
                      alt="Location img"
                      className="location-img che-loc-img"
                    />
                    <span className="p-1">{card.text}</span>
                  </div>
                  <h4 className="card-title che_3">{card.title1}</h4>
                  <div className="row">
                    <div className="col-12 d-flex align-items-center">
                      <div className="che_8 d-flex flex-row-reverse">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <React.Fragment key={star}>
                            <input
                              type="radio"
                              id={`star${star}-${index}`}
                              name={`rating-${index}`}
                              value={star}
                            />
                            <label
                              htmlFor={`star${star}-${index}`}
                              title={`${star} stars`}
                            >
                              &#9733;
                            </label>
                          </React.Fragment>
                        ))}
                      </div>
                      <span className="mt-3 text_3">{card.text3}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <div className="d-flex align-items-center me-3">
                      <img src={Days} alt="Days icon" className="me-2" />
                      <div className="reviewevent1">5 Days</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={Person} alt="Person icon" className="me-2" />
                      <div className="reviewevent1">12 Person</div>
                    </div>
                  </div>
                  <hr className="che_9 mt-3" />
                  <div className="row mt-auto">
                    <div className="col-10">
                      <span className="from-text">From</span>
                      <span className="price-text123 ms-3">₹169.00</span>
                      <s className="ms-4">₹199.00</s>
                    </div>
                    <div className="col-2 text-end">
                      <i
                        className="bi bi-bookmark clipmark chennai_bi"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
        
          {/* {cardData.map((card, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
              <div className="card border-0 card shadow  mb-5 bg-body rounded">
            
                <img
                  className="card-img-top border che_2 c_img"
                  src={imagePaths4[index % imagePaths4.length]}
                  alt="Card image"
                />
                <div className="row imagebody">
                  <div className="col-6">
                    <p className="Featured">Featured</p>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <p className="imagebodys ms-5">
                          <img src={camera}></img>5
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="imagebodys ms-4">
                          <img src={player}></img>2
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body border-0">
                  <div className="card-text che_4 mb-3">
                    <img
                      src={Location}
                      alt="Location img"
                      className="location-img che-loc-img"
                    />
                    <span className="p-1">{card.text}</span>
                  </div>
                  <h4 className="card-title che_3">{card.title1}</h4>
                  <div className="row">
                    <div className="col-12 d-flex align-items-center">
                      <div className="che_8 d-flex flex-row-reverse">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <React.Fragment key={star}>
                            <input
                              type="radio"
                              id={`star${star}-${index}`}
                              name={`rating-${index}`}
                              value={star}
                            />
                            <label
                              htmlFor={`star${star}-${index}`}
                              title={`${star} stars`}
                            >
                              &#9733;
                            </label>
                          </React.Fragment>
                        ))}
                      </div>
                      <span className="mt-3">{card.text3}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center me-3">
                      <img src={Days} alt="Days icon" className="me-2" />
                      <div className="reviewevent1">5 Days</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={Person} alt="Person icon" className="me-2" />
                      <div className="reviewevent1">12 Person</div>
                    </div>
                  </div>
                  <hr className="che_9 mt-3" />
                  <div className="row mt-auto">
                    <div className="col-10">
                      <span className="from-text">From</span>
                      <span className="price-text123 ms-3">₹169.00</span>
                      <s className="ms-4">₹199.00</s>
                    </div>
                    <div className="col-2 text-end">
                      <i
                        className="bi bi-bookmark clipmark"
                        style={{ color: "blue" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <button className="btn che_7" type="submit">
              View all
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default chennai;
