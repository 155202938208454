import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import sec1 from "../../../assets/svg/w10.svg";
// import sec2 from "../../../assets/svg/w12.svg";
// import sec3 from "../../../assets/svg/w13.svg";
// import sec4 from "../../../assets/svg/w14.svg";

const Section_0 = () => {
  // const BlurImageSection = ({ image, text, className }) => (
  //   <div className={`blur-image-section ${className}`}>
  //     <img src={image} alt={text} />
  //     <div className="overlay">
  //       <div className="text-overlay">{text}</div>
  //     </div>
  //   </div>
  // );
  const [programs, setPrograms] = useState([]);
  const navigate = useNavigate();
  const handleThemeClick = (id, themes_name) => {
    const formattedThemeName = themes_name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Remove all special characters and replace with hyphen
      .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ""); // Trim hyphens from the start and end

    // alert(id);
    navigate(`/programs/${formattedThemeName}`, { state: { id, themes_name } });
  };

  useEffect(() => {
    fetch("https://backoffice.innerpece.com/api/theme")
      .then((response) => response.json())
      .then((data) => {
        // console.log('API Data:', data); // Log the API response
        // Check if `data` has `themes` and that it is an array
        if (data && Array.isArray(data.themes)) {
          setPrograms(data.themes); // Set state with the array of themes
        } else {
          console.error(
            "Expected `themes` to be an array but got:",
            data.themes
          );
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="container-fluid  section paddings1 mt-5 mmt-0 ">
      <h1 className="Programs_text">Themes</h1>
      <div className="row g-3">
        {programs.length > 0 ? (
          programs.map((program) => (
            <div
              className="col-lg-3 col-md-6 col-sm-12"
              key={program.id}
              onClick={() => handleThemeClick(program.id, program.themes_name)}
            >
              {/* <Link to={`/popularevent/${program.id}`}> */}
              <div className="card position-relative text-white">
                <img
                  src={`https://backoffice.innerpece.com/${program.theme_pic}`}
                  alt={program.themes_name}
                  className="sec0-cont"
                />
                <div className="card-img-overlay d-flex flex-column align-items-center">
                  <h1 className="section55">{program.themes_name}</h1>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No programs available</p>
        )}
      </div>
    </div>
  );
};

export default Section_0;

/* <Link
      to="westernghats"
      style={{ textDecoration: 'none' }}
      className="col-lg-3 col-md-12 col-sm-12"
    >
      <div className="section55">
      <BlurImageSection
        image={sec1}
        text="Western Ghats"
        className="sec0-cont"
        
      />
      <h1>hii</h1>
      </div>
    </Link>
    <Link
      to="Himalayas"
      style={{ textDecoration: 'none' }}
      className="col-lg-3 col-md-12 col-sm-12"
    >
      <BlurImageSection
        image={sec2}
        text="Himalayas"
        className="sec0-cont"
      />
    </Link>
    <Link
      to="Choladynesty"
      style={{ textDecoration: 'none' }}
      className="col-lg-3 col-md-12 col-sm-12"
    >
      <BlurImageSection
        image={sec3}
        text="Chola Dynasty"
        className="sec0-cont"
      />
    </Link>
    <Link
      to="westernghats"
      style={{ textDecoration: 'none' }}
      className="col-lg-3 col-md-12 col-sm-12"
    >
      <BlurImageSection
        image={sec4}
        text="Western Ghats"
        className="sec0-cont"
      />
    </Link> */
