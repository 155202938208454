import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../assets/css/Userlogin/userlogin.css";
import Login_2 from "../../../assets/img/Userlogin/Login_2.svg";
// import Arrow_1 from "../../../assets/img/Userlogin/Arrow_1.svg";
// import Arrow_2 from "../../../assets/img/Userlogin/Arrow_2.svg";
// import Arrow_3 from "../../../assets/img/Userlogin/Arrow_3.svg";
// import Arrow_4 from "../../../assets/img/Userlogin/Arrow_4.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email address is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!password) {
      newErrors.password = "Password is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setErrors({});
    setSuccess("");

    try {
      const response = await axios.post(
        "https://backoffice.innerpece.com/api/login",
        {
          email: email,
          password: password,
        }
      );

      console.log(response.data);
      console.log(response.data.token);
      console.log(response.data.user_details);

      const loginidvalue = response.data.token;
      const loginDetails = response.data.user_details;

      sessionStorage.setItem("loginid", loginidvalue);
      sessionStorage.setItem("loginDetails", JSON.stringify(loginDetails));

      setSuccess("Login successful! Redirecting...");
      navigate("/");
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setErrors({
        ...errors,
        server: error.response?.data?.message || "Unauthorized User.",
      });
    }
  };

  return (
    <div>
      {/* Header Section */}
      <header className="header-section_33">
        <div className="overlay_33">
          {/* <div>
            <img src={Arrow_1} alt="32" />
            <img src={Arrow_2} className="flights_3" alt="33" />
          </div> */}
          <div className="col-12 text-center">
            <h1 className="header-title_33">User Login</h1>
            <nav aria-label="breadcrumb_33">
              <ol className="breadcrumb justify-content-center">
                <p>
                  <a href="/">Home</a> / User Login
                </p>
              </ol>
            </nav>
          </div>
          {/* <div>
            <img src={Arrow_4} className="flights_3" alt="35" />
            <img src={Arrow_3} alt="34" />
          </div> */}
        </div>
      </header>

      {/* Login Form Section */}
      <div className="login-page_33">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-10">
              <div className="card_33 shadow-lg border-0 rounded-lg mt-5">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-4 p-3">
                      <img
                        src={Login_2}
                        className="img-fluid rounded-start h-100 w-100 object-fit-cover"
                        alt="Scenic View"
                      />
                    </div>
                    <div className="col-lg-8 p-4">
                      <h3 className="text-start mt-2">Log In To Get Started</h3>
                      <p className="text-start text-muted mt-4">
                        <span className="badge bg-warning text-dark">
                          20% off
                        </span>{" "}
                        get 20% off for web signup
                      </p>
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center justify-content-center gap-4">
                          <div className="mb-3 col-12">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              className={`form-control p-3 ${
                                errors.email || errors.server
                                  ? "is-invalid"
                                  : ""
                              }`}
                              onChange={(e) => setEmail(e.target.value)}
                              id="email"
                              placeholder="Enter your email"
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 mt-3">
                          <label htmlFor="password" className="form-label">
                            Your Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            className={`form-control p-2 ${
                              errors.password || errors.server
                                ? "is-invalid"
                                : ""
                            }`}
                            id="password"
                            placeholder="Password"
                          />
                          {errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>

                        {/* Display server error under the password field */}
                        {errors.server && (
                          <div className="text-danger">{errors.server}</div>
                        )}

                        <small className="form-text">
                          <a href="#forgot" className="flex-end">
                            Forgot Password?
                          </a>
                        </small>

                        <hr className="my-4" />
                        <div className="d-grid mt-4">
                          <button type="submit" className="sign_in_page_1">
                            Sign In
                          </button>
                        </div>
                      </form>
                      <div className="mt-4 text-start">
                        {/* <p>
                          By signing up, you agree to our{" "}
                          <a href="#terms">Terms Of Service</a> and{" "}
                          <a href="#privacy">Privacy Policy</a>
                        </p> */}
                        <p>
                          Don't have an account?
                          <Link to="/usersignup">
                            <button className="Register_1">Register</button>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
