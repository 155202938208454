import React, { useState } from "react";
// import { Link } from "react-router-dom";

import "../../../assets/css/Events/Upcomingevents.css";
import Location from "../../../assets/img/Events/location.img.svg";
import Booking from "../../../assets/img/Events/bicycle_img.svg";
import data from "../../../assets/img/Events/calendar_img.svg";
import Guests from "../../../assets/img/Events/user.img.svg";

import Round from "../../../assets/img/Events/round_img.svg";
import Round1 from "../../../assets/img/Events/round_img1.svg";
import Person from "../../../assets/img/Events/person_img.svg";
import Days from "../../../assets/img/Events/days_img.svg";
// import Upcomingevent1 from "../../../assets/img/Events/upcomingevent_1.svg";
// import Upcomingevent2 from "../../../assets/img/Events/upcomingevent_2.svg";
// import Upcomingevent3 from "../../../assets/img/Events/upcomingevent_3.svg";
// import Upcomingevent4 from "../../../assets/img/Events/upcomingevent_4.svg";
// import Upcomingevent5 from "../../../assets/img/Events/upcomingevent_5.svg";
import Search from "../../../assets/img/Events/search.svg";

import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import { Modal, Button } from "react-bootstrap";
function PopularEvents() {
  // const [minPrice, setMinPrice] = useState(3000);
  // const [maxPrice, setMaxPrice] = useState(20000);
  // // const [showModal, setShowModal] = useState(false);

  // const handleRangeChange = (event) => {
  //   const value = parseInt(event.target.value, 10);
  //   if (event.target.id === "minRange") {
  //     setMinPrice(value);
  //   } else {
  //     setMaxPrice(value);
  //   }
  // };

  const [programData, setProgramData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { id, city_name } = location.state || {}; // Retrieve the id from location.state

  // const [title, setTittle] = useState('');

  const [destination, setDestination] = useState("");

  useEffect(() => {
    if (city_name) {
      // alert(id);
      // console.log('ID:', id); // Log the id to the console

      //api

      // setTittle(id)
      // setTheme(themes_name)
      setDestination(city_name);
    }
  }, [id, city_name]);

  useEffect(() => {
    const fetchProgramData = async () => {
      try {
        const response = await axios.post(
          "https://backoffice.innerpece.com/api/get-program",
          {
            // theme: id,
            program_destination: id,
            // view_type: 'all'
          }
        );

        setProgramData(response.data.data);
        // console.log(response.data.data)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProgramData();
  }, []);

  document.addEventListener("DOMContentLoaded", () => {
    const ratingStars = document.querySelectorAll(
      '.star-rating1 input[type="radio"]'
    );
    ratingStars.forEach((star) => {
      star.addEventListener("change", (event) => {
        console.log(`Rated ${event.target.value} stars`);
      });
    });
  });

  const [value, setValue] = useState(3000);
  const minValue = 3000;
  const maxValue = 25000;

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const [selectedValue, setSelectedValue] = useState("1");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleFilterChange = (filterType, value) => {
    console.log(`Filter changed: ${filterType} = ${value}`);
  };

  const navigate = useNavigate();

  const handleClick = (id) => {
    // alert(id)
    navigate("/sample", { state: { id } });
  };

  return (
    <>
      <div className="section2 mt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 tiimg">
              <div className="WesternSidebar1">
                <div className="Western-Navigation-bar1">
                  <p className="hmmtr">Home &gt; Explore {city_name}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="titleBackground1 img-fluid p-4">
                  <div className="banner text-center1">
                    <div>
                      <h5 className="banner-title1">Explore {destination}</h5>
                    </div>
                    {/* <h6 className="banner-desc1 mt-2">
                      Lorem ipsum dolor sit amet consectetur. Sed egestas mauris
                      ornare amet egestas.
                    </h6> */}
                    <div className="inputContainer justify-content-center align-items-center mt-4">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text border-0 bg-transparent">
                            <img src={Search} alt="Search Icon" />
                          </span>
                        </div>
                        <input
                          type="text"
                          placeholder="Search Events"
                          className="form-control border-0"
                        />
                      </div>
                      <button className="btn bbttnn">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row totalpages">
            {/* Offcanvas Trigger Button for Mobile */}
            <button
              className="btn btn-primary d-lg-none mb-3"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasSidebar"
              aria-controls="offcanvasSidebar"
            >
              Open Filters
            </button>
            {/* Sidebar (Visible on Large Screens) */}
            <div className="col-lg-3 sidebarr d-none d-lg-block">
              <div
                className="p-5 border rounded d-grid"
                style={{ fontWeight: "600" }}
              >
                <p className="serchfilters"> Search By Filter</p>

                {/* Location Filter */}
                <div className="row border p-2 ps-2 rounded align-items-center sidenav">
                  <div className="col-2">
                    <img src={Location} alt="Location img" />
                  </div>
                  <div className="col-10">
                    <select
                      className="col-12 border-0 p-1 fontcolor"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <option value="1">Location</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                {/* Booking Type Filter */}
                <div className="row border p-2 ps-2 rounded align-items-center mt-3 sidenav">
                  <div className="col-2">
                    <img src={Booking} alt="Booking img" />
                  </div>
                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Booking Type</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                {/* Date Filter */}
                <div className="row border p-2 ps-2 rounded align-items-center mt-3 sidenav">
                  <div className="col-2">
                    <img src={data} alt="Data img" />
                  </div>
                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Date Form</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                {/* Guests Filter */}
                {/* <div className="row border p-2 ps-2 rounded align-items-center mt-3 sidenav">
                  <div className="col-2">
                    <img src={Guests} alt="Guests img" />
                  </div>
                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Guests</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div> */}

                {/* Price Filter */}
                <div className="filter-container55 mt-3 sidenav">
                  <h6 className="subtitle p2 mb-3">Filter by Price</h6>
                  <div className="col-11">
                    <input
                      type="range"
                      className="form-range"
                      id="amountRange"
                      min={minValue}
                      max={maxValue}
                      value={value}
                      step="100"
                      onChange={handleInputChange}
                      style={{
                        WebkitAppearance: "none",
                        width: "100%",
                        height: "8px",
                        background: `linear-gradient(to right, #007bff ${
                          ((value - minValue) / (maxValue - minValue)) * 100
                        }%, #ddd ${
                          ((value - minValue) / (maxValue - minValue)) * 100
                        }%)`,
                        borderRadius: "5px",
                        outline: "none",
                      }}
                    />
                    <label htmlFor="amountRange" className="form-label mt-3">
                      <span className="filter_price">Price: </span>
                      <span className="range-value">
                        ₹{minValue} - ₹{value}
                      </span>
                    </label>
                  </div>
                  <div className="ttrre">
                    <input className="btn fill" type="button" value="FILTER" />
                  </div>
                </div>

                {/* Additional Filters */}
                {/* <div className="row border p-2 ps-2 rounded mt-3 sidenav">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Language</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="row border p-2 ps-2 rounded mt-3 sidenav">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Month</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="row border p-2 ps-2 rounded mt-3 sidenav">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Duration</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div> */}
              </div>

              {/* Additional Features Section */}
              {/* <div className="p-3 border rounded adfetures">
                <h6 className="subtitle ms-3 p1">Additional Features</h6>
                {["10+", "12+", "15+", "Adults"].map((activity, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start my-2 ms-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input border-checkbox"
                      onClick={() => handleFilterChange("activity", activity)}
                    />
                    <div
                      className="px-2"
                      onClick={() => handleFilterChange("activity", activity)}
                    >
                      {activity}
                    </div>
                  </div>
                ))}
              </div> */}

              {/* More Features Section */}
              {/* <div className="p-3 border rounded sdadded">
                <h6 className="subtitle ms-3 p1">More Features</h6>
                {[
                  "Accepts Credit Card",
                  "Car Parking",
                  "Free Coupons",
                  "Laundry Service",
                  "Outdoor Seating",
                  "Reservation",
                  "Restaurant",
                  "Smoking Allowed",
                ].map((activity, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start my-2 ms-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input border-checkbox"
                      onClick={() => handleFilterChange("activity", activity)}
                    />
                    <div
                      className="px-2"
                      onClick={() => handleFilterChange("activity", activity)}
                    >
                      {activity}
                    </div>
                  </div>
                ))}
              </div> */}
            </div>

            {/* Offcanvas Sidebar (Visible on Mobile Screens) */}
            <div
              className="offcanvas offcanvas-start"
              tabIndex="-1"
              id="offcanvasSidebar"
              aria-labelledby="offcanvasSidebarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasSidebarLabel">
                  Search By Filter
                </h5>
                <button
                  type="button"
                  className="btn-close mt-5"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <p className="serchfilters"> Search By Filter</p>

                {/* Location Filter */}
                <div className="row border p-2 ps-2 rounded align-items-center sidenav">
                  <div className="col-2">
                    <img src={Location} alt="Location img" />
                  </div>
                  <div className="col-10">
                    <select
                      className="col-12 border-0 p-1 fontcolor"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <option value="1">Location</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                {/* Booking Type Filter */}
                <div className="row border p-2 ps-2 rounded align-items-center mt-3 sidenav">
                  <div className="col-2">
                    <img src={Booking} alt="Booking img" />
                  </div>
                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Booking Type</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                {/* Date Filter */}
                <div className="row border p-2 ps-2 rounded align-items-center mt-3 sidenav">
                  <div className="col-2">
                    <img src={data} alt="Data img" />
                  </div>
                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Date </option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                {/* Guests Filter */}
                <div className="row border p-2 ps-2 rounded align-items-center mt-3 sidenav">
                  <div className="col-2">
                    <img src={Guests} alt="Guests img" />
                  </div>
                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Guests</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>

                {/* Price Filter */}
                <div className="filter-container55 mt-3 sidenav">
                  <h6 className="subtitle p2 mb-3">Filter by Price</h6>
                  <div className="col-11">
                    <input
                      type="range"
                      className="form-range"
                      id="amountRange"
                      min={minValue}
                      max={maxValue}
                      value={value}
                      step="100"
                      onChange={handleInputChange}
                      style={{
                        WebkitAppearance: "none",
                        width: "100%",
                        height: "8px",
                        background: `linear-gradient(to right, #007bff ${
                          ((value - minValue) / (maxValue - minValue)) * 100
                        }%, #ddd ${
                          ((value - minValue) / (maxValue - minValue)) * 100
                        }%)`,
                        borderRadius: "5px",
                        outline: "none",
                      }}
                    />
                    <label htmlFor="amountRange" className="form-label mt-3">
                      <span className="filter_price">Price: </span>
                      <span className="range-value">
                        ₹{minValue} - ₹{value}
                      </span>
                    </label>
                  </div>
                  <div className="ttrre mb-4">
                    <input className="btn fill" type="button" value="FILTER" />
                  </div>
                </div>

                {/* Additional Filters */}
                <div className="row border p-2 ps-2 rounded mt-3 sidenav mt-4">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Language</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="row border p-2 ps-2 rounded mt-3 sidenav">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Month</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="row border p-2 ps-2 rounded mt-3 sidenav">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Duration</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                {/* Additional Features */}
                <h6 className="subtitle ms-3 p1 mt-4">Additional Features</h6>
                {["10+", "12+", "15+", "Adults"].map((activity, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start my-2 ms-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input border-checkbox"
                      onClick={() => handleFilterChange("activity", activity)}
                    />
                    <div
                      className="px-2"
                      onClick={() => handleFilterChange("activity", activity)}
                    >
                      {activity}
                    </div>
                  </div>
                ))}

                {/* More Features */}
                <h6 className="subtitle ms-3 p1 mt-4">More Features</h6>
                {[
                  "Accepts Credit Card",
                  "Car Parking",
                  "Free Coupons",
                  "Laundry Service",
                  "Outdoor Seating",
                  "Reservation",
                  "Restaurant",
                  "Smoking Allowed",
                ].map((activity, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start my-2 ms-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input border-checkbox"
                      onClick={() => handleFilterChange("activity", activity)}
                    />
                    <div
                      className="px-2"
                      onClick={() => handleFilterChange("activity", activity)}
                    >
                      {activity}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-lg-9 col-md-8 col-sm-12 p-5 d-grid gap-3 totals_column">
              <div className="d-flex justify-content-between border p-2 rounded mtpar">
                <div>
                  <h3 className="page-title mt-2">
                    {" "}
                    Showing {programData.length} of {programData.length} Results
                  </h3>
                </div>
                <div className="most_loved">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Sort By: Most Loved </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              {loading && <p>Loading...</p>}
              {error && <p>Error: {error}</p>}
              {programData.length > 0 ? (
                programData.map((event, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between row rounded mb-4 sharder cardsize"
                  >
                    <div
                      className="card col-lg-4 col-md-4 col-sm-1 justify-content-center align-items-center"
                      id="popular_145"
                    >
                      <img
                        src={`https://backoffice.innerpece.com/${event.cover_img}`}
                        alt="Location img"
                        className="full-size-img"
                      />
                      <p className="ftrr">Featured</p>
                    </div>
                    <div className="col-lg-8 col-sm-12 col-md-8 p-3 total_body_coloum">
                      <div className="row">
                        <div className="col-lg-8 col-sm-12 d-flex">
                          <div className="me-3">
                            <img
                              src={Location}
                              alt="Location img"
                              className="location-img img-fluid"
                              id="lls"
                            />
                          </div>
                          <p>{event.location}</p>
                        </div>
                        <div className="col-2 col-lg-1 col-sm-2 imggscol imggscoll">
                          <img
                            src={Round}
                            alt="Location img"
                            className="rounded-circle "
                            id="llss"
                          />
                        </div>
                        <div className="col-2 col-lg-1 col-sm-2  imggscol imggscoll">
                          <img
                            src={Round1}
                            alt="Location img"
                            className="rounded-circle "
                            id="lls"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 Fontevent">
                          <h2 className="titletx">{event.title}</h2>
                        </div>
                      </div>
                      <div className="row strrors">
                        <div className="col-4">
                          <div className="star-rating1">
                            <input
                              type="radio"
                              id={`star5-${index}`}
                              name={`rating-${index}`}
                              value="5"
                            />
                            <label htmlFor={`star5-${index}`} title="5 stars">
                              &#9733;
                            </label>
                            <input
                              type="radio"
                              id={`star4-${index}`}
                              name={`rating-${index}`}
                              value="4"
                            />
                            <label htmlFor={`star4-${index}`} title="4 stars">
                              &#9733;
                            </label>
                            <input
                              type="radio"
                              id={`star3-${index}`}
                              name={`rating-${index}`}
                              value="3"
                            />
                            <label htmlFor={`star3-${index}`} title="3 stars">
                              &#9733;
                            </label>
                            <input
                              type="radio"
                              id={`star2-${index}`}
                              name={`rating-${index}`}
                              value="2"
                            />
                            <label htmlFor={`star2-${index}`} title="2 stars">
                              &#9733;
                            </label>
                            <input
                              type="radio"
                              id={`star1-${index}`}
                              name={`rating-${index}`}
                              value="1"
                            />
                            <label htmlFor={`star1-${index}`} title="1 star">
                              &#9733;
                            </label>
                          </div>
                        </div>
                        <div className="col-8 mt-2">
                          <p className="reviewevent">(1 Review)</p>
                        </div>
                      </div>
                      <div className="row align-items-center strrors">
                        <div className="col-lg-3 col-md-3 col-sm-2 d-flex align-items-center person_column">
                          <img src={Days} alt="Days img" className=" me-1" />
                          <div className="">
                            <p className="reviewevent1 mt-3">
                              {event.total_days} Days
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-2 ms-4 d-flex align-items-center person_column">
                          <img src={Person} alt="Days img" className="me-1" />
                          <div>
                            <p className="reviewevent1 mt-3">
                              {event.member_capacity} Person
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr className="light-line popular_1" />
                      <div className="row d-flex mb-3  popular_1">
                        <div className="col-lg-8 col-md-6 col-sm-2 d-flex">
                          <span className="from-text ">From</span>
                          <span className="price-text23 ms-2 ">
                            &#8377;{event.actual_price}
                          </span>
                          <s className="ms-3">&#8377;{event.price}</s>

                          <div className="col-lg-4 col-md-6 col-sm-12 linkButton45">
                            <div
                              className="btn linkButton1"
                              onClick={() => handleClick(event.id)}
                              style={{ cursor: "pointer" }}
                            >
                              View <i className="fas fa-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No programs available.</p>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <nav
            aria-label="Page navigation example"
            className="page_nation gap-4"
          >
            <ul className="pagination rounded-pagination gap-4">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true" className="arraw">
                    &laquo;
                  </span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default PopularEvents;
