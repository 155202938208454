import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/Pages/Filterbox.css'; // Custom styles
import Calender from "../../../assets/img/Calender.svg";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { FaAngleDown } from "react-icons/fa6";

const FilterBox = () => {
  // const [sortBy, setSortBy] = useState('Price');
  const [filters, setFilters] = useState({
    luxury: false,
    beachfront: false,
    pool: false,
  });
  const [date, setDate] = useState('');
  const [startValue, setStartValue] = useState(25);
  const [endValue, setEndValue] = useState(75);

  // const handleSortChange = (value) => {
  //   setSortBy(value);
  // };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.checked,
    });
  };

  const handleDateChange = (selectedDates) => {
    const selectedDate = selectedDates[0];
    setDate(moment(selectedDate).format('DD/MM/YYYY'));
  };

  const handleStartChange = (e) => {
    const value = parseInt(e.target.value);
    if (value < endValue) {
      setStartValue(value);
    }
  };

  const handleEndChange = (e) => {
    const value = parseInt(e.target.value);
    if (value > startValue) {
      setEndValue(value);
    }
  };

  return (
    <div className='d-flex justify-content-center filter-container'>
      <div className='filter-icon-container'>
        <TbAdjustmentsHorizontal className='filter-icon'/>
      </div>
      <div className="filter-bar d-flex flex-row flex-nowrap overflow-auto p-3">
        <div className="btn-group me-3">
          {/* SortBy Button */}
          <div className='d-flex justify-content-center align-items-center btns' data-bs-toggle="offcanvas"
            data-bs-target="#sortByOffcanvas" 
            aria-controls="sortByOffcanvas"
          >
          <div
            className="filter-btn"
          >
            SortBy
          </div>
          <FaAngleDown/>
          </div>
          {/* Category div */}
          <div className='d-flex justify-content-center align-items-center btns' data-bs-toggle="offcanvas"
            data-bs-target="#categoryOffcanvas"
            aria-controls="categoryOffcanvas">
          <div
            className="btn"
          >
            Category
          </div>
          <FaAngleDown/>
          </div>
          {/* Price div */}
          <div className='d-flex justify-content-center align-items-center btns' data-bs-toggle="offcanvas"
            data-bs-target="#priceOffcanvas"
            aria-controls="priceOffcanvas">
          <div
            className="btn" 
          >
            Price
          </div>
          <FaAngleDown/>
          </div>
          {/* Date div */}
          <div className='d-flex justify-content-center align-items-center btns' data-bs-toggle="offcanvas"
            data-bs-target="#dateOffcanvas"
            aria-controls="dateOffcanvas">
          <div
            className="btn"
          >
            Date
          </div>
          <FaAngleDown/>
          </div>
          {/* Activity div */}
          <div className='d-flex justify-content-center align-items-center btns'             data-bs-toggle="offcanvas"
            data-bs-target="#activityOffcanvas"
            aria-controls="activityOffcanvas">
          <div
            className="btn"
          >
            Activity
          </div>
          <FaAngleDown/>
          </div>
          {/* Pool div */}
          <div className='d-flex justify-content-center align-items-center btns' data-bs-toggle="offcanvas"
            data-bs-target="#poolOffcanvas"
            aria-controls="poolOffcanvas">
          <div
            className="btn"
          >
            Pool
          </div>
          <FaAngleDown/>
          </div>
        </div>

        {/* Sort By Offcanvas */}
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="sortByOffcanvas" aria-labelledby="sortByOffcanvasLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="sortByOffcanvasLabel">Sort By Filters</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            {['Beach', 'Club', 'Cultural', 'Natural Tourism'].map((activity, index) => (
              <div key={index} className="d-flex justify-content-start my-2">
                <input type='checkbox' className="form-check-input border-checkbox" onChange={() => handleFilterChange({ target: { name: activity, checked: true } })} />
                <div className='px-2'>{activity}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Category Offcanvas */}
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="categoryOffcanvas" aria-labelledby="categoryOffcanvasLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="categoryOffcanvasLabel">Category Filters</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            {['Promo Deals', 'One Day Trip', 'Top Vacation', 'Things To do'].map((category, index) => (
              <div key={index} className="d-flex my-1">
                <input type='checkbox' className="form-check-input border-checkbox" onChange={() => handleFilterChange({ target: { name: category, checked: true } })} />
                <div className='ps-2'>{category}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Price Offcanvas */}
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="priceOffcanvas" aria-labelledby="priceOffcanvasLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="priceOffcanvasLabel">Price Filters</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className="dual-handle-progress-container">
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={startValue}
                onChange={handleStartChange}
                className="progress-bar start"
              />
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={endValue}
                onChange={handleEndChange}
                className="progress-bar end"
              />
              <div className="progress-value start">{startValue}%</div>
              <div className="progress-value end">{endValue}%</div>
            </div>
          </div>
        </div>

        {/* Date Offcanvas */}
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="dateOffcanvas" aria-labelledby="dateOffcanvasLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="dateOffcanvasLabel">Date Filters</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className="d-flex">
              <div className='label d-flex align-items-center justify-content-center'>
                <img className='label-date' src={Calender} alt='Calender' />
              </div>
              <Flatpickr
                className="inputs"
                placeholder="DD/MM/YYYY"
                value={date}
                onChange={handleDateChange}
                options={{
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd-m-Y',
                  allowInput: true,
                  parseDate: (datestr, format) => moment(datestr, format, true).toDate(),
                  formatDate: (date, format) => moment(date).format(format),
                }}
              />
            </div>
          </div>
        </div>

        {/* Activity Offcanvas */}
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="activityOffcanvas" aria-labelledby="activityOffcanvasLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="activityOffcanvasLabel">Activity Filters</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            {['Beach', 'Club', 'Cultural', 'Natural Tourism'].map((activity, index) => (
              <div key={index} className="d-flex justify-content-start my-2">
                <input type='checkbox' className="form-check-input border-checkbox" onChange={() => handleFilterChange({ target: { name: activity, checked: true } })} />
                <div className='px-2'>{activity}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Pool Offcanvas */}
        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="poolOffcanvas" aria-labelledby="poolOffcanvasLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="poolOffcanvasLabel">Pool Filters</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            {[5, 4, 3, 2, 1].map((rating, index) => (
              <div key={index} className="d-flex my-2">
                <input type='checkbox' className="form-check-input border-checkbox" onChange={() => handleFilterChange({ target: { name: rating, checked: true } })} />
                <div>{rating} Star</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBox;