import React from 'react';
import '../../../assets/css/detail/detail.css'
import Section1 from '../../../views/components/Detail/section1'



function Detail() {
  return (
    <>
       <Section1/>
    </>
  );
}

export default Detail;
