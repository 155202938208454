// import React from "react";
// import { Link } from "react-router-dom";
// import loc1 from "../../../assets/svg/loc_1.svg";
// import loc2 from "../../../assets/svg/loc_2.svg";
// import loc3 from "../../../assets/svg/loc_3.svg";
// import loc4 from "../../../assets/svg/loc_4.svg";
// import loc5 from "../../../assets/svg/loc_5.svg";
// import loc6 from "../../../assets/svg/loc_6.svg";
// import loc7 from "../../../assets/svg/loc_7.svg";
// import loc8 from "../../../assets/svg/loc_8.svg";

// const CardComponent = ({ image, title, subtitle }) => {
//   return (
//     <div className="card card_1 Nearby  " style={{  height: "114px",width:"100%",justifyContent:"center" }}>
//       <div className="row g-0 container-fluid">
//         <div className="col-4 col-md-3 d-flex align-items-center justify-content-center">
//           <img
//             src={image}
//             className="img-fluid rounded-circle ms-5"
//             alt="..."
//             style={{ width: "80px", height: "80px" }}
//           />
//         </div>
//         <div className="col-8 col-md-9">
//           <div className="card-body">
//             <h5 className="card-title_1 ms-3" style={{  marginTop: "28px" }}>{title}</h5>
//             {/* <p className="card-text_1">{subtitle}</p> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Section_1 = () => {
//   return (
//     <div className="container-fluid paddings mt-5 ">
//       <h1 className="loc_title mb-4">Destination</h1>
//       <div className="row g-4">
//         <div className=" col-12 col-lg-3 col-md-6">
//           <Link to={"Chennai"} style={{ textDecoration: "none" }}>
//             <div className="col-12 col-md-6 col-lg-3 w-100">
//               <CardComponent
//                 image={loc1}
//                 title="Chennai"
//                 // subtitle="21 Events"
//               />
//             </div>
//           </Link>
//         </div>
//         <div className=" col-12 col-lg-3 col-md-6">
//           <Link to={"Coimbatore"} style={{ textDecoration: "none" }}>
//             <div className="col-12 col-md-12 col-lg-12 ">
//               <CardComponent
//                 image={loc2}
//                 title="Coimbatore"
//                 // subtitle="21 Events"
//               />
//             </div>
//           </Link>
//         </div>
//         <div className="col-12 col-md-6 col-lg-3">
//           <CardComponent image={loc3} title="Bangalore"  />
//         </div>

//         <div className="col-12 col-md-6 col-lg-3">
//           <CardComponent image={loc4} title="Hyderabad"  />
//         </div>
//       </div>
//       {/* <div className="row g-4 mt-1 mb-3">
//         <div className="col-12 col-md-6 col-lg-3">
//           <CardComponent image={loc5} title="Mumbai" />
//         </div>
//         <div className="col-12 col-md-6 col-lg-3">
//           <CardComponent image={loc6} title="Kerala" />
//         </div>
//         <div className="col-12 col-md-6 col-lg-3">
//           <CardComponent image={loc7} title="Kolkata"  />
//         </div>
//         <div className="col-12 col-md-6 col-lg-3">
//           <CardComponent image={loc8} title="Delhi"  />
//         </div>
//       </div> */}
//     </div>
//   );
// };

// export default Section_1;
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CardComponent = ({ image, title }) => {
  return (
    <div
      className="card card_1 mb-3 Nearby"
      style={{ height: "114px", width: "100%" }}
    >
      <div className="row g-0 container-fluid">
        <div className="col-4 d-flex align-items-center justify-content-center">
          <img
            src={image}
            className="img-fluid rounded-circle mt-3 ms-5"
            alt={title}
            style={{ width: "80px", height: "80px" }}
          />
        </div>
        <div className="col-8">
          <div className="card-body">
            <h5 className="card-title_1 ms-3" style={{ marginTop: "28px" }}>
              {title}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section_1 = () => {
  const [destination, setDestination] = useState([]); // Initialize as an empty array
  const navigate = useNavigate();

  const handleDestinationClick = (id, city_name) => {
    // console.log("id", id);
    // console.log("destination name", city_name);
    const formattedThemeName = city_name
      .toLowerCase()
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Remove all special characters and replace with hyphen
      .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, "");
    // alert(id);
    // alert(city_name)
    navigate(`/destination/${formattedThemeName}`, {
      state: { id, city_name },
    });
  };

  // const handleCardClick = (id) => {
  //   // Handle the POST request when a card is clicked
  //   const formData = new FormData();

  //   // Append the id to the FormData
  //   formData.append("program_destination", id);

  //   // Make the POST request using axios
  //   axios
  //     .post("https://backoffice.innerpece.com/api/get-program", formData)
  //     .then((response) => {
  //       console.log(response.data.data);
  //       // Handle the response data
  //     })
  //     .catch((error) => {
  //       console.error("There was an error with the POST request!", error);
  //     });
  // };

  useEffect(() => {
    axios
      .get("https://backoffice.innerpece.com/api/destination")
      .then((response) => {
        // Check if response data is an array
        // console.log(response.data.destination_list);
        if (Array.isArray(response.data.destination_list)) {
          // console.log(response.data.destination_list);
          setDestination(response.data.destination_list);
        } else {
          console.error(
            "Unexpected response format:",
            response.data.destination_list
          );
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  return (
    <div className="container-fluid p-5">
      <h1 className="mb-4 loc_title mtext-center">Destination</h1>
      {/* <Link to={'/upcomingevents'} style={{ textDecoration: "none" }}> */}
      <div className="row g-4 ">
        {destination.length > 0 ? (
          destination.map((item, index) => (
            // console.log(item)
            <div
              className="col-12 col-lg-3 col-md-6"
              key={index}
              onClick={() => handleDestinationClick(item.id, item.city_name)}
            >
              {/* <Link to={`/upcomingevents/${item.id}`} style={{ textDecoration: "none" }}> */}
              <CardComponent
                image={`https://backoffice.innerpece.com/${item.cities_pic}`}
                title={item.city_name}
                // Pass the id when clicked
              />

              {/* </Link> */}
            </div>
          ))
        ) : (
          <p>No destinations available</p>
        )}
      </div>
      {/* </Link> */}
    </div>
  );
};

export default Section_1;
