import React from "react";
import { Row, Col } from "react-bootstrap";
import "../../../assets/css/Destination/Destination.css";
import Desinationheader from "../../../assets/img/Destination/Desinationheader.svg";
import desinationimg_1 from "../../../assets/img/Destination/desinationimg_1.svg";
import desinationimg_2 from "../../../assets/img/Destination/desinationimg_2 (2).svg";
import desinationimg_3 from "../../../assets/img/Destination/desinationimg_3.svg";
import desinationimg_4 from "../../../assets/img/Destination/desinationimg_4.svg";
import desinationimg_5 from "../../../assets/img/Destination/desinationimg_5.svg";
import desinationimg_6 from "../../../assets/img/Destination/desinationimg_6.svg";

import desinationimg_7 from "../../../assets/img/Destination/desinationimg_7.svg";
import Desarraw from "../../../assets/img/Destination/Des_arraw_1.svg";
import desinationimg_8 from "../../../assets/img/Destination/desinationimg_8.svg";
import desinationimg_9 from "../../../assets/img/Destination/desinationimg_9.svg";

function Destination() {
  return (
    <>
      <header className="header">
        <div className="header-bg">
          <img src={Desinationheader} alt="Skyline" className="header-image c_img" />
        </div>
        <div>
          <h1 className="header-title mt-5">DESTINATIONS</h1>
        </div>
        <div className="search-bar mt-5">
          <input type="text" className="form-control inputbar" placeholder="Search" />
          <button className="btn btn-primary  ">
            <i className="bi bi-search"></i>
          </button>
        </div>

        <div class="container des-over  ">
          <figure  className="col-sm-12">
            <img
              src={desinationimg_1}
              alt="A windmill"
              className="col-sm-12"
            ></img>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur3 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_3 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                      className="col-xs-12">
                      </img>
                  </div>
              </div>
            </div>
            <div className="col-12 Agra_img">
                    <h2 className="col-12 text-start Des_word_1 ">Agra</h2>
                    <p className="col-12 text-start  Des_word_2">Taj Mahal</p>
                  </div>
          </figure>
          <figure  className="col-sm-12 ">
            <div className="Des_col_133">
            <img
              src={desinationimg_2}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12 "
            ></img>
            </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                       className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Madurai</h2>
                    <p className="col-12 text-start Des_word_2">Meenakshi Temple</p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_3}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12"
            ></img>
            </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                      className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Coorg</h2>
                    <p className="col-12 text-start Des_word_2">Abbi Falls</p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_4}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12"
            ></img>
            </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                       className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Goa</h2>
                    <p className="col-12 text-start Des_word_2">Baga Beach</p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_5}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12"
            ></img>
            </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                       className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Srinager</h2>
                    <p className="col-12 text-start Des_word_2">Dal Lake</p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_6}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12"
            ></img>
             </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                       className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Wayanad</h2>
                    <p className="col-12 text-start Des_word_2">Wayanad Wildlife Sanctuary</p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_7}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12"
            ></img>
            </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                       className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Jaipur</h2>
                    <p className="col-12 text-start Des_word_2">nagargargh Fort </p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_7}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12"
            ></img>
            </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                       className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Jaipur</h2>
                    <p className="col-12 text-start Des_word_2">nagargargh Fort </p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_8}
              alt="The Clifton Suspension Bridge"
              className="col-sm-12"
            ></img>
             </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur2 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_4 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                       className="des_img_77"
                      >
                      </img>
                  </div>   
              </div>
            </div>
            <div className="col-12 Agra_img_1">
                    <h2 className="col-12 text-start Des_word_1">Kashmir</h2>
                    <p className="col-12 text-start Des_word_2">Shankaracharya Hills </p>
                  </div>
          </figure>
          <figure  className="col-sm-12">
          <div className="Des_col_133">
            <img
              src={desinationimg_9}
              alt="A windmill"
              className="col-sm-12"
            ></img>
            </div>
             <div class="overlay">
              <div className="row">
                <div className="col-12 ">
                  <a className=" col-6 blur3 start-end" href="#">
                    All Packages
                  </a>
                  </div>
                  <div className=" Agra_img_3 col-12 align-items-center justify-content-center">
                    <img
                      src={Desarraw}
                      alt="A windmill"
                      className="col-xs-12">
                      </img>
                  </div>
              </div>
            </div>
            <div className="col-12 Agra_img">
                    <h2 className="col-12 text-start Des_word_1 ">Munnar</h2>
                    <p className="col-12 text-start  Des_word_2">Kollukumali Tea Estate</p>
                  </div>
          </figure>
        </div>
        <Row className="text-center mt-4">
          <Col>
            <button type="button" className="disbutton ">
              Learn More
            </button>
          </Col>
        </Row>
      </header>
    </>
  );
}

export default Destination;
