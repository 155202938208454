import React from "react";
import { useState } from "react";
import "../../../assets/css/sample/sample.css";
import info from "../../../assets/img/sample/information.png";
import dect from "../../../assets/img/sample/destination.png";
import map from "../../../assets/img/sample/map.png";
import fav from "../../../assets/img/sample/favourite.png";
import gallery from "../../../assets/img/sample/gallery.png";

import time from "../../../assets/img/sample/time.png";
import mask from "../../../assets/img/sample/Mask.png";
import vect from "../../../assets/img/sample/Vect.png";
import share from "../../../assets/img/sample/share.png";
import star from "../../../assets/img/sample/Star.png";
import staret from "../../../assets/img/sample/Staret.png";

import { FaPaperPlane } from "react-icons/fa";
import defaultimg from "../../../assets/img/defaultimg11.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

import { FacebookShareButton } from "react-share";

function Sec1() {
  const [programDetails, setProgramDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setCommends] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  // const location = useLocation();
  // const { id } = location.state || {};
  const { title, id } = useParams();
  const [isWishlisted, setIsWishlisted] = useState(false);

  const tourPlanningRef = useRef(null);
  const locationShare = useRef(null);
  const reviewsRef = useRef(null);
  const galleryRef = useRef(null);
  const infoRef = useRef(null);
  const scrollToTourPlanning = () => {
    tourPlanningRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToLocationShare = () => {
    locationShare.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToReviewShare = () => {
    reviewsRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToGalleryShare = () => {
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToInfoShare = () => {
    infoRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchProgramDetails = async () => {
      try {
        const storedUserDetails = sessionStorage.getItem("loginDetails");
        const userDetails = storedUserDetails
          ? JSON.parse(storedUserDetails)
          : null;

        const payload = {
          program_id: id,
          user_id: userDetails?.id || null,
        };

        const response = await axios.post(
          "https://backoffice.innerpece.com/api/get-program-details",
          payload
        );

        if (response.data.status === "success") {
          const programData = response.data.data;
          setProgramDetails(programData);
          setIsWishlisted(programData.wishlists);

          // Update meta tags dynamically
          document.title = programData.title || "Default Title";

          const metaOgTitle = document.querySelector(
            "meta[property='og:title']"
          );
          if (metaOgTitle) {
            metaOgTitle.setAttribute(
              "content",
              programData.title || "Default Title"
            );
          }

          console.log("metaOgTitle", metaOgTitle);

          const metaOgDescription = document.querySelector(
            "meta[property='og:description']"
          );
          if (metaOgDescription) {
            metaOgDescription.setAttribute(
              "content",
              programData.program_desc || "Default description"
            );
          }

          console.log("metaOgDescription", metaOgDescription);

          const metaOgImage = document.querySelector(
            "meta[property='og:image']"
          );
          if (metaOgImage) {
            metaOgImage.setAttribute(
              "content",
              `https://backoffice.innerpece.com/${programData.cover_img}` || ""
            );
          }

          console.log("metaOgImage", metaOgImage);
        } else {
          setError(response.data.message || "Failed to fetch program details.");
        }
      } catch (error) {
        setError("An error occurred while fetching the program details.");
      } finally {
        setLoading(false);
      }
    };

    fetchProgramDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const currentUrl = window.location.href;
  // const currentUrl = `https://demo.innerpece.com/ladakh-bike-expedition-8n-9d`;

  // console.log('currentUrl',currentUrl);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});
    setSuccess("");

    try {
      const response = await axios.post(
        "https://backoffice.innerpece.com/api/enquiry-form",
        {
          name: name,
          email,
          phone,
          comments,
        }
      );

      setSuccess("Enquiry submitted successfully!");

      navigate("/submit"); // Replace with your target path
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setErrors({
        server: error.response?.data?.message || "Please try again.",
      });
    }
  };

  const handleWishlistClick = async () => {
    const loginDetails = JSON.parse(sessionStorage.getItem("loginDetails"));
    const token = sessionStorage.getItem("loginid");

    if (!loginDetails || !loginDetails.id) {
      console.error("User is not logged in");
      navigate("/userlogin");
      return;
    }

    const { id: user_id } = loginDetails;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("program_id", id);
    formData.append("action", isWishlisted ? "remove" : "add");

    try {
      const response = await axios.post(
        "https://backoffice.innerpece.com/api/add-remove-wishlist",
        formData,
        { headers }
      );

      if (response.status === 201 || response.status === 200) {
        console.log(
          `${isWishlisted ? "Removed from" : "Added to"} wishlist successfully.`
        );
        setIsWishlisted(!isWishlisted);
      } else {
        console.error("Failed to update wishlist.", response);
      }
    } catch (error) {
      console.error("An error occurred while updating wishlist:", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="background-image33 ">
        <div className="col-12 main_img1" ref={galleryRef}>
          <img
            src={
              programDetails.cover_img
                ? `https://backoffice.innerpece.com/${programDetails.cover_img}`
                : defaultimg
            }
            alt="Cover"
            className="main_img"
          />
        </div>

        <div className="nav">
          <ul className="lis">
            <a onClick={scrollToInfoShare} className="li underline">
              <img src={info} alt="Information" className="icon_image " />
              Information
            </a>
            <a
              onClick={scrollToTourPlanning}
              className="li underline"
              style={{ cursor: "pointer" }}
            >
              <img src={dect} alt="Tour Planning" className="icon_image" />
              Tour Planning
            </a>
            <a onClick={scrollToLocationShare} className="li underline">
              <img src={map} alt="Location Share" className="icon_image" />
              Location Share
            </a>
            <a onClick={scrollToReviewShare} className="li underline">
              <img src={fav} alt="Reviews" className="icon_image" />
              Reviews
            </a>
            {/* <a onClick={scrollToGalleryShare} className="li underline">
              <img src={gallery} alt="Shot Gallery" className="icon_image" />
              Shot Gallery
            </a> */}
          </ul>
        </div>
      </div>
      {/* <div className="container-fluid">
        <div className="row pt-5 Pakage_sec_2">
          <div class="col-md-9"></div>
          <div class="col-md-3"></div>
        </div>
      </div> */}

      <div className="container-fluid mt-2">
        <div className="row Pakage_sec_2">
          <div className="col-lg-9 col-md-12 mt-5">
            <div className="mobile-price-popup d-none  price-card border-0 shadow-lg mb-5 bg-body d-sm-none rounded ms-2 card p-4 text-center">
              <div className="mb-3">
                <span className="price-card_1">Starting From</span>
                <span className="text-muted ms-2 text-decoration-line-through price-card_2">
                  INR {programDetails.discount_price}
                </span>
              </div>
              <h2 className="text-success_1 mb-3">
                INR {programDetails.actual_price}
              </h2>
              <div className="d-flex justify-content-around mt-3">
                <button
                  className="btn btn-danger d-flex align-items-center mb-3 ms-2"
                  onClick={handleShow}
                >
                  <FaPaperPlane className="me-2" />
                  Send Enquiry
                </button>
              </div>
            </div>
            <div className="pinkpalace">{programDetails.title}</div>
            <div className="ulii mb-3">
              <ul className="ulii mt-2">
                <li className="listt">
                  <img src={time} alt="Time" className="pl-2" />5 Hours
                </li>
                <li className="listt">
                  <img src={mask} alt="Mask" className="pl-2" />
                  {programDetails.member_capacity}
                </li>
                <li className="listt">
                  <img src={vect} alt="Vect" className="pl-2" />
                  {programDetails.state},{programDetails.city},
                  {programDetails.country}
                </li>
              </ul>
            </div>
            {programDetails.gallery_img.length > 0 && (
              <div className="col-md-11 pl-5 mb-3">
                <div className="card het">
                  <img
                    src={`https://backoffice.innerpece.com/${programDetails.gallery_img[0]}`}
                    alt="Image 1"
                    className="imgg1"
                  />
                </div>
              </div>
            )}
            <h1 className="PropertyHighlights mt-3" id="home">
              Property Highlights
            </h1>
            {/* <h2 className="paras">{programDetails.program_desc}</h2> */}
            <h2
              className="paras"
              dangerouslySetInnerHTML={{
                __html: programDetails.program_desc,
              }}
            />
            {/* <h2 className="paras">{programDetails.program_desc}</h2> */}
            {/* <h3 className="read">
              Read More<img src={errow} alt="Read More Arrow"></img>
            </h3> */}
            {programDetails.amenity_details &&
              Object.keys(programDetails.amenity_details).length > 0 && (
                <div className="border1234">
                  <div
                    className="row innersection-border mt-1 p-3"
                    style={{ marginRight: "5%" }}
                  >
                    <div className="row Amenities_1">
                      <div className="inner-subtitle mb-1">Amenities</div>
                      <div className="col-md-12">
                        <div className="col-12">
                          {Object.keys(programDetails.amenity_details).map(
                            (key, index) => {
                              const amenity =
                                programDetails.amenity_details[key];

                              return (
                                <h3 className="texts col-sm-12" key={key}>
                                  <img
                                    src={`https://backoffice.innerpece.com/${amenity.amenity_pic}`}
                                    className="imagess"
                                    alt={amenity.amenity_name}
                                  />
                                  <span>{amenity.amenity_name}</span>
                                </h3>
                              );

                              return null;
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {programDetails.foodBeverages &&
              Object.keys(programDetails.foodBeverages).length > 0 && (
                <div className="border1234">
                  <div
                    className="row innersection-border mt-1 p-3"
                    style={{ marginRight: "5%" }}
                  >
                    <div className="inner-subtitle mb-1">
                      Food and Beverages
                    </div>
                    <div className="col-md-12">
                      <div>
                        {Object.keys(programDetails.foodBeverages).map(
                          (key, index) => {
                            const foodItem = programDetails.foodBeverages[key];

                            return (
                              <h3 className="texts" key={key}>
                                <img
                                  src={`https://backoffice.innerpece.com/${foodItem.food_beverage_pic}`}
                                  className="imagess"
                                  alt={foodItem.food_beverage}
                                />
                                <span>{foodItem.food_beverage}</span>
                              </h3>
                            );

                            return null;
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {programDetails.safety_features &&
              Object.keys(programDetails.safety_features).length > 0 && (
                <div className="mt-4">
                  <div
                    className="row innersection-border p-3 mt-5"
                    style={{ marginRight: "5%" }}
                    id="roo"
                  >
                    <div className="mb-1 inner-subtitle">Safety Features</div>
                    <div className="col-md-12">
                      {Object.keys(programDetails.safety_features).map(
                        (key, index) => {
                          const feature = programDetails.safety_features[key];

                          return (
                            <h3 className="texts" key={key}>
                              <img
                                src={`https://backoffice.innerpece.com/${feature.safety_features_pic}`}
                                className="imagess"
                                alt={feature.safety_features}
                              />
                              <span>{feature.safety_features}</span>
                            </h3>
                          );

                          return null;
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}

            {programDetails.activities &&
              Object.keys(programDetails.activities).length > 0 && (
                <div className="mt-5">
                  <div className="inner-subtitle mb-3 ms-3">Activities</div>
                  <div className="row">
                    {Object.keys(programDetails.activities).map(
                      (key, index) => {
                        const activity = programDetails.activities[key];
                        return (
                          <div
                            className="col-6 activities col-md-3 mb-4"
                            key={index}
                          >
                            <div className="activities-img">
                              <img
                                src={`https://backoffice.innerpece.com/${activity.activities_pic}`}
                                className="card-img-top activityimg"
                                alt={activity.activities}
                              />
                              <div className="activities-text">
                                <h5 className="">{activity.activities}</h5>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            {/* Camp Rules Section */}
            {programDetails.payment_policy &&
              programDetails.payment_policy.length > 0 && (
                <div className="row mt-5" id="roo">
                  <div className="mb-4 ms-4 inner-subtitle">Payment Policy</div>
                  <div className="col-12">
                    <h5 className="camptext paras">
                      {programDetails.payment_policy.map((rule, index) => (
                        <span key={index}>
                          {index + 1}. {rule}
                          <br />
                        </span>
                      ))}
                    </h5>
                  </div>
                </div>
              )}
          </div>

          <div className="col-lg-3 mt-5">
            <div className=" col-md-12">
              <div className="bbt d-flex">
                <ul className="col-12 share_1234">
                  <li className="btnss">
                    <FacebookShareButton
                      url={currentUrl}
                      quote={title}
                      hashtag="#innerpece"
                    >
                      <button className="bts" type="submit">
                        <img src={share} alt="Share" className="me-2" />
                        Share
                      </button>
                    </FacebookShareButton>
                  </li>
                  <li className="btnss ms-2 ">
                    <button
                      className="bts"
                      type="submit"
                      onClick={() => handleWishlistClick(id)}
                    >
                      {/* <img src={heart} alt="Heart" className="me-2" /> */}
                      {isWishlisted ? (
                        <i
                          className="bi bi-heart-fill me-2"
                          style={{ color: "red" }}
                        ></i>
                      ) : (
                        <i className="bi bi-heart me-2"></i>
                      )}
                      Wishlist
                    </button>
                  </li>
                </ul>
              </div>
              <div className="stat ">
                <ul className="me-5">
                  {[...Array(5)].map((_, starIndex) => (
                    <li className="stars" key={starIndex}>
                      <img
                        src={
                          starIndex < Math.round(programDetails.total_reviews)
                            ? star
                            : staret
                        }
                        alt="Star"
                      />
                    </li>
                  ))}
                  <li className="stars ms-3" id="sampl">
                    {programDetails.total_reviews} Reviews
                  </li>
                </ul>
              </div>
            </div>
            <div className="price-card border-0 shadow-lg mb-5 bg-body d-none d-lg-block d-md-block rounded ms-2 card p-4 text-center">
              <div className="mb-3">
                <span className="price-card_1">Starting From</span>
                <span className="text-muted ms-2 text-decoration-line-through price-card_2">
                  INR {programDetails.discount_price}
                </span>
              </div>
              <h2 className="text-success_1 mb-3">
                INR {programDetails.actual_price}
              </h2>
              <div className="d-flex justify-content-around mt-3">
                <button
                  className="btn btn-danger d-flex align-items-center mb-3 ms-2"
                  onClick={handleShow}
                >
                  <FaPaperPlane className="me-2" />
                  Send Enquiry
                </button>
              </div>

              <Modal
                show={show}
                className="popup_123 "
                onHide={handleClose}
                centered
              >
                <Modal.Header closeButton className="modal_123">
                  <Modal.Title className="headig_sample1 ">
                    Inner pece
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row p-4">
                    <div className="col-12 col-md-6 d-flex flex-column align-items-center">
                      <img
                        className="img_123456 img-fluid"
                        src={
                          programDetails.gallery_img[0]
                            ? `https://backoffice.innerpece.com/${programDetails.gallery_img[0]}`
                            : defaultimg
                        }
                        alt="Pink Palace"
                      />

                      <h1 className="headig_sample2 mt-3">
                        {programDetails.title}
                      </h1>
                      <div className="headig_sample5 text-center mt-3">
                        <div className="mb-3">
                          <span className="price-card_1">Starting From</span>
                          <span className="text-muted ms-2 text-decoration-line-through price-card_2">
                            INR {programDetails.discount_price}
                          </span>
                        </div>
                        <h2 className="text-success_1 mb-3">
                          INR {programDetails.actual_price}
                        </h2>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      {errors.server && (
                        <div className="alert alert-danger">
                          {errors.server}
                        </div>
                      )}
                      {success && (
                        <div className="alert alert-success">{success}</div>
                      )}
                      <form onSubmit={handleSubmit}>
                        <div className="input-group mb-3 headig_sample7">
                          <span className="input-group-text">
                            <i className="bi bi-person"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control headig_sample4"
                            placeholder="Name"
                            id="name"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                        <div className="input-group mb-3 headig_sample7">
                          <span className="input-group-text">
                            <i className="bi bi-envelope"></i>
                          </span>
                          <input
                            type="email"
                            className="form-control headig_sample4"
                            placeholder="Email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        {errors.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                        <div className="input-group mb-3 headig_sample7">
                          <span className="input-group-text">
                            <i className="bi bi-telephone"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control headig_sample4"
                            placeholder="Phone"
                            id="phone"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        {errors.phone && (
                          <div className="invalid-feedback">{errors.phone}</div>
                        )}
                        <div className="input-group mb-3 headig_sample7">
                          <span className="input-group-text">
                            <i className="bi bi-chat-dots"></i>
                          </span>
                          <textarea
                            className="form-control headig_sample55 border"
                            id="message"
                            placeholder="Comments"
                            rows="3"
                            onChange={(e) => setCommends(e.target.value)}
                          ></textarea>
                        </div>
                        {errors.message && (
                          <div className="invalid-feedback">
                            {errors.message}
                          </div>
                        )}
                        <div className="headig_sample6 text-center">
                          <Button variant="primary" type="submit">
                            Send me Details
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            <h4 className="ms-5 mb-2" ref={locationShare}>
              Where you'll be
            </h4>
            <div md={6} className="map_123" id="notifications">
              <iframe
                title="office-location"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3917.042127194694!2d79.38192267573746!3d10.960190455775871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s35-C%2C%20Sevaliye%20Sivaji%20Nagar%2C%20Darasuram%2C%20Kumbakonam!5e0!3m2!1sen!2sin!4v1725463089931!5m2!1sen!2sin"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {programDetails.important_info.length > 0 && (
        <div className="container-fluid mt-5" ref={infoRef}>
          <div className="row mt-5 Pakage_sec_2" id="roo">
            <div className="col-lg-8">
              <div className="ms-3 inner-subtitle mb-3">Important Info</div>
              <div className="camptext paras">
                {programDetails.important_info}
              </div>
            </div>
          </div>
        </div>
      )}

      {programDetails.tour_planning.plan_title.length > 0 && ( // Check for presence and length
        <div
          className="container-fluid mt-5 totalimagesec6"
          ref={tourPlanningRef}
          id="search"
        >
          <div className="row mt-5 Pakage_sec_2" id="roo">
            <div className="mt-5 col-12">
              <div className="ms-3 inner-subtitle mb-3">Tour Planning</div>
            </div>
            <div className="col-12 col-lg-8">
              <div className=" roadmap">
                <div className="card-body day_1">
                  {programDetails.tour_planning.plan_title.map(
                    (title, index) => (
                      <div key={index}>
                        <div className="card-title ">{title}</div>
                        <h5 className="day_2">
                          {programDetails.tour_planning.plan_subtitle[index]}
                        </h5>
                        <h3
                          className="textmessage card-text paras"
                          dangerouslySetInnerHTML={{
                            __html:
                              programDetails.tour_planning.plan_description[
                                index
                              ],
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {programDetails?.client_reviews?.length > 0 && (
        <div
          className="container-fluied Pakage_sec_2 mt-5"
          id="profile"
          ref={reviewsRef}
        >
          <div className="row mt-5">
            <div className="col-12 col-md-6">
              <div className="client_review pl-2"> Client’s Review</div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row ">
                <div className="col-12 col-sm-6">
                  <div className="stat">
                    <ul className="list-unstyled d-flex">
                      <li className="stars">
                        <h4 className="reviews ">
                          {programDetails?.total_reviews} Reviews
                        </h4>
                      </li>
                      {[...Array(5)].map((_, starIndex) => (
                        <li className="stars " key={starIndex}>
                          <img
                            src={
                              starIndex <
                              Math.round(programDetails.total_reviews)
                                ? star
                                : staret
                            }
                            alt="Star"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 outof">
                  <h3 className="reviews">
                    ({programDetails?.average_rating} out of 5)
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="row">
                <div className="col-lg-8 ">
                  {programDetails?.client_reviews?.map((review, index) => (
                    <div className="row mt-5" key={index}>
                      <div className="col-3">
                        <img
                          className="img_123456 img-fluid"
                          src={`https://backoffice.innerpece.com/${review.client_pic}`}
                          alt="client pic"
                        />
                      </div>
                      <div className="col-9">
                        <h5 className="reviewers">{review.client_name}</h5>
                        <p
                          className="viewer_comment paras"
                          dangerouslySetInnerHTML={{
                            __html: review.client_review,
                          }}
                        />
                        <div className="review_star">
                          <ul className="list-unstyled d-flex">
                            {[...Array(5)].map((_, starIndex) => (
                              <li className="stars" key={starIndex}>
                                <img
                                  src={
                                    starIndex < review.rating ? star : staret
                                  }
                                  alt="Star"
                                />
                              </li>
                            ))}
                            <li className="stars">
                              <h4 className="reviews">{review.rating}</h4>
                            </li>
                          </ul>
                        </div>
                        <p className="review_date">{review.review_dt}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 reviewcolumn mt-5 mt-lg-0">
              <div className="review_background">
                <div className="reviewround">
                  <div className="reviewer_round">
                    <h1 className="overall_Ratings">Overall Ratings</h1>
                    <h1 className="rating">{programDetails?.average_rating}</h1>
                    <h1 className="out_of">Out of 5</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sec1;
