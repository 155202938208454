import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/svg/innerpece-logo svg.svg";
import "../../assets/css/style.css";

import axios from "axios";

function Header() {
  const [navbarBackground, setNavbarBackground] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [headerContent, setHeaderContent] = useState(null);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isLoggedIn = sessionStorage.getItem("loginid") !== null; // Adjust this condition based on your logic
  const userDetails = JSON.parse(sessionStorage.getItem("loginDetails"));

  useEffect(() => {
    const fetchHeaderContent = async () => {
      try {
        const response = await axios.get(
          "https://backoffice.innerpece.com/api/header-content"
        );
        setHeaderContent(response.data);
      } catch (error) {
        console.error("There was an error fetching header content!", error);
      }
    };

    fetchHeaderContent();
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setNavbarBackground(true);
    } else {
      setNavbarBackground(false);
    }
  };

  const listclick = () => {
    const navbarCollapse = document.getElementById("navbarNav");
    if (navbarCollapse) {
      new window.bootstrap.Collapse(navbarCollapse).hide();
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath === "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [location]);

  useEffect(() => {
    if (isHomePage) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHomePage]);

  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  //   sessionStorage.removeItem("loginid"); // Clear the session
  //   navigate("/userlogin"); // Redirect to the login page
  // };

  const handleLogout = () => {
    // Clear session storage and handle logout logic
    sessionStorage.removeItem("loginid");
    sessionStorage.removeItem("loginDetails");
    navigate("/userlogin");
    // You can also perform additional logout actions if necessary
  };

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top ${
        isHomePage && !navbarBackground
          ? "bg-white shadow-sm black-navbar-class"
          : "bg-white shadow-sm black-navbar-class"
      }`}
    >
      <div className="container-fluid">
        {headerContent && (
          <Link className="navbar-brand" to="/">
            <img id="logo" src={logo} alt="Logo" />
          </Link>
        )}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse bg-white" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item" onClick={listclick}>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item" onClick={listclick}>
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item" onClick={listclick}>
              <Link className="nav-link" to="/contact">
                Contact us
              </Link>
            </li>
            {/* {!isLoggedIn ? (
              <li className="nav-item" onClick={listclick}>
                <Link className="nav-link" to="/userlogin">
                  <button className="Loginbutton">Login</button>
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <button className="Logoutbutton" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            )} */}

            <ul className="navbar-nav">
              {!isLoggedIn ? (
                <li className="nav-item" onClick={listclick}>
                  <Link className="nav-link" to="/userlogin">
                    <button className="Loginbutton">Login</button>
                  </Link>
                </li>
              ) : (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <i class="bi bi-person-circle custom-icon"></i>
                    {userDetails?.first_name}
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <button className="dropdown-item" onClick={handleLogout}>
                        Logout
                      </button>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
