import React from "react";
import Termsconditionimg from "../../../assets/img/Terms_condition_img.svg";
import "../../../assets/css/Privacypolicy/privacypolicy.css";
import { Link } from "react-router-dom";

function Termsofservices() {
  return (
    <>
      <div className="row ">
        <div className="col-12">
          <div className="terms-container1 position-relative">
            <img src={Termsconditionimg} alt="123" className="main_img123" />
            <div className="term_1234">
              <h1 class="privacy-policy-title">TERMS AND CONDITION</h1>
              <Link to="/">
  <p className="home-link-privacy">HOME</p>
</Link>
<span> / </span>
<span className="privacy-policy-3">TERMS AND CONDITION</span>

            </div>
          </div>
        </div>
      </div>
      {/* <div class="container">
            
            <nav class="breadcrumb">
                <a href="#" class="breadcrumb-item">Home</a> /
                <span class="breadcrumb-item active">Privacy Policy</span>
            </nav>
        </div> */}
      <div class="container terms_and_policy">
        <h2>Terms and condition </h2>
        <p className="paras">
          Welcome to InnerPece (the "Site"). This Site is owned and operated by
          InnerPece Solutions Pvt Ltd., a corporation established under Indian
          law. By accessing or using this Site, you agree to the terms and
          conditions laid out below without limitation or qualification. If you
          access any subsidiary site or service linked to this Site, please be
          aware that specific terms may apply to those subsites
        </p>

        <h3>Visa Policy</h3>
        <p className="paras">
          1.Visa approval is solely at the discretion of immigration
          authorities. 2.If your flight is booked and there is a delay in visa
          approval, InnerPece is not responsible for any cancellations or
          associated costs. 3.Rejected visa applications are final, and the visa
          processing fee is non-refundable. 4.In case of overstay, a penalty of
          USD 1535 will be imposed, payable by the customer or agent. 5.If a
          passenger is involved in illegal activities, all fines and penalties
          are the responsibility of the agent.
        </p>

        <h3>Site Usage</h3>
        <p className="paras">
          This Site is for personal use only. You may not distribute, modify,
          sell, or transmit any content from this Site for commercial purposes
          without explicit permission from InnerPeace. By using this Site,
          InnerPece grants you a non-exclusive, non-transferable, limited right
          to access, view, and use the Site. Any disruption or attempt to
          disrupt the operation of this Site is strictly prohibited. Some areas
          of the Site may require you to register for access. If you register,
          you must provide accurate and truthful information about yourself.
        </p>

        <h3>Intellectual Property</h3>
        <p className="paras">
          content on this Site, including text, images, software, and audio, is
          protected by international copyright laws. You may download or copy
          content for personal use only, provided you do not remove any
          copyright or trademark notices. InnerPece holds all rights to its
          logos, trademarks, and service marks. Any unauthorized use of these
          materials is expressly prohibited.
        </p>

        <h3>User Conduct</h3>
        <p className="paras">
          If this Site provides communication tools such as forums or chat
          rooms, you agree to use them responsibly. You may not:
        </p>
        <ul>
          <li className="paras">Defame, harass, or violate the legal rights of others.</li>
          <li className="paras">Upload files that carry viruses or corrupted data.</li>
          <li className="paras">
            Post any material that infringes on intellectual property rights.
          </li >
          <li className="paras">Advertise or sell goods or services without permission.</li>
          <li className="paras">
            Misrepresent your identity or the origin of the content you post.
          </li>
        </ul>

        <h3>User Submissions</h3>
        <p className="paras">
          You are prohibited from submitting any unlawful, defamatory, or
          obscene material to the Site. InnerPece is not liable for any such
          material posted by users but reserves the right to remove it.
          InnerPece will cooperate fully with law enforcement authorities or
          court orders requesting disclosure of user identities.
        </p>

        <h3>Content Use and Submission</h3>
        <p className="paras">
          By submitting content or communications to InnerPece, you grant us
          the right to use, modify, publish, or distribute that content without
          any claim for compensation or acknowledgment.
        </p>

        <h3>Security and Privacy</h3>
        <p className="paras">
          Internet transmissions are never completely secure. InnerPece does
          not guarantee the security of any information you send to the Site,
          and you accept the risk that your information may be intercepted. The
          content on this Site is the property of InnerPece and is protected by
          copyright law. Unauthorized copying or use of the content is
          prohibited.
        </p>

        <h3>Agency Terms</h3>
        <p className="paras">
          1. Payments for services must be made within the specified timeframe.
          Late payments may incur interest charges. 2. Refunds will be processed
          according to our policies and those of our suppliers. 3.Agents are
          responsible for all tax obligations, including TDS. 4. Credit limits
          are set every week, and advance payments must be utilized within a
          specified period.
        </p>
        <h3>Disclaimer</h3>
        <p className="paras">
          The content on this Site is provided "as is" without any warranties,
          expressed or implied. InnerPece does not guarantee the accuracy or
          reliability of the content and is not responsible for any errors or
          omissions. InnerPece is not liable for any loss or damage arising
          from your use of this Site, including indirect or consequential
          losses.
        </p>
        <h3>Availability of Services</h3>
        <p className="paras">
          Products and services displayed on this Site may not be available in
          all locations. Availability may vary, and InnerPece does not
          guarantee that services will be available in your area.
        </p>
        <h3>Changes to Terms</h3>
        <p className="paras">
          InnerPece holds the right to amend these terms and conditions at any
          time without prior notice. Changes will take effect immediately upon
          posting on the Site. Continued use of the Site after changes are
          posted indicates your acceptance of the new terms.
        </p>
        <h3>Legal Compliance</h3>
        <p className="paras">
          All disputes related to these terms will be resolved through
          arbitration under Indian law, with proceedings conducted in English.
          The arbitration venue will be New Delhi, India. By using this Site,
          you acknowledge and accept the risks associated with travel to certain
          destinations. InnerPece is not liable for any damages or losses
          resulting from travel to such destinations.
        </p>
        <h3>Grievance Redressal</h3>
        <p className="paras"> 
          For any grievances or concerns, please contact us at
          jay@innerpeace.com.
        </p>
        {/* <p><strong>Email:</strong> support@innerpeace.com<br>
            <strong>Address:</strong> 123 Wellness Road, Blisstown, Earthlandia<br>
            <strong>Phone:</strong> +1-234-567-890</p> */}
      </div>
    </>
  );
}
export default Termsofservices;
