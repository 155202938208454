import React from "react";
import Termsconditionimg from "../../../assets/img/Terms_condition_img.svg";
import "../../../assets/css/Privacypolicy/privacypolicy.css";
import { Link } from "react-router-dom";

function Termsofservices() {
  return (
    <>
      <div className="row ">
        <div className="col-12">
          <div className="terms-container1 position-relative">
            <img src={Termsconditionimg} alt="123" className="main_img123" />
            <div className="term_1234">
              <h1 class="privacy-policy-title">TERMS OF SERVICES</h1>
              <Link to="/">
                <p class="home-link-privacy">HOME</p>
              </Link>
              <span> / </span>{" "}
              <span class="privacy-policy-3">TERMS OF SERVICES</span>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="container">
            
            <nav class="breadcrumb">
                <a href="#" class="breadcrumb-item">Home</a> /
                <span class="breadcrumb-item active">Privacy Policy</span>
            </nav>
        </div> */}
      <div class="container terms_and_policy">
        <h3 className="Please_1234">Please Read Carefully</h3>
        <p className="paras">
          Thank you for choosing InnerPece for your travel needs! These terms
          and conditions outline your agreement with us. By finalizing your
          booking, you agree to these terms and conditions. Please read them
          thoroughly to ensure a smooth and enjoyable experience.
        </p>

        <h3>Tour Agreement</h3>
        <p className="paras">
          Your relationship with InnerPece is crucial to us. The details of your
          agreement with us are kept strictly confidential. We are here to
          assist with any disputes or issues you may encounter. Only designated
          representatives can modify these terms, and all promotional offers
          must be confirmed in writing.
        </p>

        <h3>Booking Confirmation</h3>
        <p className="paras">
          To confirm your booking, a minimum deposit is required. The amount
          varies by tour package. For any questions or clarifications about your
          booking, please contact us at jay@innerpeace.com. Our team is ready to
          assist you with all your tour-related needs.
        </p>

        <h3>Payment Information</h3>
        <p className="paras">
          After making the deposit, you must pay the remaining balance within
          one week. In some cases, full payment may be required before the start
          of your holiday. We will notify you of the balance due date directly
          or via email. Not paying on time could lead to the cancellation of
          your booking.
        </p>

        <h3>Changes to Your Booking</h3>
        <p className="paras">
          To make changes to your booking, please visit our office with your
          receipt. We can accommodate changes only in exceptional circumstances
          and generally allow only minor adjustments. Frequent modifications are
          not permitted.
        </p>

        <h3>Cancellation Policy</h3>
        <p className="paras">
          You may cancel your booking under specific conditions. Notify us via
          phone or email within the given timeframe to cancel. Please note that
          cancellation charges may apply, and your deposit is non-refundable.
        </p>

        <h3>Feedback and Complaints</h3>
        <p className="paras">
          If you have any issues or concerns about your tour, please reach out
          to us at We are committed to resolving your concerns and will respond
          within 24 hours.
        </p>

        <h3>Non-Smoking Policy</h3>
        <p className="paras">
          Smoking is not permitted during your travels with us. To maintain a
          pleasant environment for all, please refrain from smoking in any of
          our vehicles.
        </p>

        <h3>Our Responsibilities</h3>
        <p className="paras">
          InnerPece is dedicated to providing quality travel arrangements with
          attention to detail. We are responsible for our employees, agents, and
          suppliers during your trip. However, we are not liable for any illegal
          or violent incidents. Please act responsibly and adhere to legal norms
          during your travel.
        </p>

        <h3>Accuracy of Information</h3>
        <p className="paras">
          We strive to ensure that all information on our website, brochures,
          and advertisements is accurate and up-to-date. However, changes and
          errors may occur. We recommend reviewing our terms before booking. For
          any questions or clarifications, contact us at jay@innerpeace.com.
        </p>
        {/* <p><strong>Email:</strong> support@innerpeace.com<br>
            <strong>Address:</strong> 123 Wellness Road, Blisstown, Earthlandia<br>
            <strong>Phone:</strong> +1-234-567-890</p> */}
      </div>
    </>
  );
}
export default Termsofservices;
