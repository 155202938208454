import React from "react";
import Termsconditionimg from "../../../assets/img/Terms_condition_img.svg";
import "../../../assets/css/Privacypolicy/privacypolicy.css";
import { Link } from "react-router-dom";

function Termsofservices() {
  return (
    <>
      <div className="row ">
        <div className="col-12">
          <div className="terms-container1 position-relative">
            <img src={Termsconditionimg} alt="123" className="main_img123" />
            <div className="term_1234">
              <h1 class="privacy-policy-title">PRIVACY POLICY</h1>
              <Link to="/">
                <p class="home-link-privacy">HOME</p>
              </Link>
              <span> / </span> <span class="privacy-policy-3">PRIVACY POLICY</span>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="container">
            
            <nav class="breadcrumb">
                <a href="#" class="breadcrumb-item">Home</a> /
                <span class="breadcrumb-item active">Privacy Policy</span>
            </nav>
        </div> */}
      <div class="container terms_and_policy">
        <h2>Innerpece- Privacy Policy</h2>
        <p className="paras">
          At Innerpece, we prioritize your privacy and are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, disclose, and secure your data when you engage with
          our website and services. Our goal is to ensure transparency and trust
          in all our interactions with you.
        </p>

        <h3>Information We Collect</h3>
        <p className="paras" >
          To ensure you receive top-notch service, we gather the following types
          of information:
        </p>
        <ul>
          <li className="paras">
            <h5> Personal Information:</h5> This includes your name, email
            address, phone number, and other contact details necessary for
            booking and communication.
          </li>
          <li className="paras">
            <h5> Booking Details:</h5> Information related to your travel
            preferences, reservations, and transactions.
          </li>
        </ul>

        <h3>Customer Interactions:</h3>
        <ul>
          <li className="paras">
            Data from your interactions with our customer service team,
            including queries, feedback, and support requests.We collect this
            information through:
          </li>
        </ul>

        <h3>We collect this information through:</h3>
        <ul>
          <li className="paras">
            <h5>Account Creation:</h5> When you register on our website or book
            a service.
          </li>
          <li className="paras">
            <h5>Subscriptions:</h5> When you sign up for our newsletters,
            alerts, or special offers.
          </li>
          <li className="paras">
            <h5> Customer Service:</h5> When you reach out to us for support or
            inquiries.
          </li>
          <li className="paras">
            <h5> Third-Party Sources:</h5> Information obtained from our
            partners to enhance your experience
          </li>
        </ul>

        <h3>How We Use Your Information</h3>
        <p className="paras">
          The information we collect is used to: Provide Services: Facilitate
          your bookings, process payments, and offer tailored travel
          experiences.
        </p>
        <ul>
          <li className="paras">
            <h5> Communicate with You:</h5> Send updates, promotional offers,
            and other relevant information. You're free to unsubscribe from
            marketing communications whenever you like.
          </li>
          <li className="paras">
            <h5>Personalize Your Experience:</h5> Customize the content and
            services we provide based on your preferences and feedback.
          </li>
          <li className="paras">
            <h5>Improve Our Offerings:</h5> Conduct research and analysis to
            enhance our products, services, and customer experience.
          </li>
        </ul>

        <h3>Sharing Your Information</h3>
        <p>We may share your personal information with:</p>
        <ul>
          <li className="paras">
            <h5> Service Providers: </h5> Third-party vendors who assist us in
            delivering our services, such as payment processors and travel
            partners.
          </li>
          <li className="paras">
            <h5>Legal Obligations:</h5> Authorities or legal entities if
            required by law or to protect our rights and safety.
          </li>
          <li className="paras">
            <h5>Business Transactions:</h5>In cases of mergers, acquisitions, or
            similar business transfers, your information may be included as part
            of the transaction.
          </li>
        </ul>

        <h3>Data Security</h3>
        <p className="paras">
          We implement strong security measures to protect your personal
          information from unauthorized access, misuse, or disclosure. While we
          take every precaution, we also advise you to protect your data by
          using strong passwords and logging out of your accounts when using
          public devices.
        </p>

        <h3>International Data Transfers</h3>
        <p className="paras">
          Due to our global operations, your information might be transferred
          and processed in countries other than your own. We ensure that such
          transfers comply with relevant data protection laws and maintain the
          security of your information throughout the process.
        </p>

        <h3>Third-Party Websites</h3>
        <p className="paras">
          Our website may contain links to external sites that are not governed
          by this Privacy Policy. We encourage you to review the privacy
          policies of these third-party websites before sharing any personal
          information, as we are not responsible for their practices.
        </p>

        <h3>Cookies and Tracking</h3>
        <p className="paras">
          We use cookies and other tracking technologies to enhance your
          experience on our website, analyze usage patterns, and deliver
          relevant advertising. You can control your cookie preferences through
          your browser settings. Please refer to our website's cookie policy for
          more details.
        </p>
        <h3>Your Rights</h3>
        <p className="paras">
          You have the right to access, correct, or delete your personal
          information at any time. To make use of these rights, reach out to us
          using the contact information provided below. For information obtained
          through third parties, please reach out to them directly.
        </p>

        <h3>Changes to This Policy</h3>
        <p className="paras">
          We may revise this Privacy Policy from time to time to align with
          evolving practices or legal obligations. Significant updates will be
          communicated through email or posted on our website. We recommend
          checking this policy periodically to stay informed about how we
          safeguard your information.
        </p>

        <h3>Contact Us</h3>
        <p className="paras">
          If you have any questions, concerns, or requests regarding your
          personal information or this Privacy Policy, please reach out to us
          at.
        </p>
        <h3>Innerpece</h3>
        <p className="paras">
          35-C, Sevaliye Sivaji Nagar, Darasuram, Kumbakonam,<br></br>
          jay@innerpeace.com,<br></br>
          +91750214903.<br></br>
        </p>
        <p className="paras">
          We appreciate your trust in Innerpece and are dedicated to
          safeguarding your privacy while providing a secure and enjoyable
          experience.
        </p>
        {/* <p><strong>Email:</strong> support@innerpeace.com<br>
            <strong>Address:</strong> 123 Wellness Road, Blisstown, Earthlandia<br>
            <strong>Phone:</strong> +1-234-567-890</p> */}
      </div>
    </>
  );
}
export default Termsofservices;
