import { useEffect, useState } from "react";
import React from "react";

// import human from "../../assets/svg/human.svg";
// import innerpece from "../../assets/svg/innerpece-logo-_white.svg";
import mail from "../../assets/svg/footer_mail.svg";
import call from "../../assets/svg/footer_call.svg";
import location from "../../assets/svg/footer_location.svg";
// import getin from "../../assets/svg/getin_touch.svg";
// import ff1 from "../../assets/svg/ff1.svg";
// import ff2 from "../../assets/svg/ff2.svg";
// import ff3 from "../../assets/svg/ff3.svg";
// import ff4 from "../../assets/svg/ff4.svg";
import fff1 from "../../assets/svg/fff1.svg";
import fffff3 from "../../assets/svg/twitters13.svg";
import fff3 from "../../assets/svg/fff3.svg";
// import Blur from "../../assets/svg/blur_123.png";
import fff4 from "../../assets/svg/fff4.svg";
// import footers from "../../assets/svg/footer_logo_new.svg";
import { Link } from "react-router-dom";
import axios from "axios";

function Footer() {
  const [footercontent, setfootercontent] = useState(null);
  useEffect(() => {
    axios
      .get("https://backoffice.innerpece.com/api/footer-content")
      .then((response) => {
        setfootercontent(response.data);
        // console.log("response",response.data)
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  return (
    <div className=" bac_img float-start w-100">
      {/* <div className="foot_top_bg">   
          </div> */}
      <div className="container-fluid">
        <div className="row p-5">
          <div className="row align-content-center">
            {footercontent && (
              <div className="col-lg-3 col-md-6 float-center">
                <div>
                  <img
                    src={`https://backoffice.innerpece.com/${footercontent.footer_logo}`}
                    alt="Footer_logo"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-5">
                  <p className="footer_col_1_p">
                    Innerpece is a travel curator that transforms your travel
                    ideas<br></br> into customized, unforgettable journeys.
                  </p>
                </div>
                <div>
                  <p>
                    <img src={mail} alt="#" className="mail_img" />
                    <a href="mailto:jay@innerpeace.com" style={{ textDecoration: "none", color: "white" }}>
                    <span className="img_textss">
                      {footercontent.contact_email}
                    </span>
                    </a>
                  </p>
                </div>
                <div>
                  <p>
                    <img src={call} alt="#" className="mail_img" />
                    <a href="tel:91750214903" style={{ textDecoration: "none", color: "white" }}>
                    <span className="img_textss">
                      {footercontent.contact_number}
                    </span>
                    </a>
                  </p>
                </div>
                <div>
                  <p>
                    <img src={location} alt="#" className="mail_img" />
                    <span className="img_textss">
                      {footercontent.contact_address}
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div className="col-lg-3 col-md-6 float-start mtext-center">
              <h4 className="text-white service_12 text-center">
                Services Req
              </h4>
              <div className="footer_col2_text mt-3 text-center">
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p>Faqs</p>
                </Link>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p>About Us</p>
                </Link>
                {/* <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "white" }}
                >
                <p>Podcasts</p>
                </Link> */}

                {/* <p style={{ textDecoration: "none",color:"white" }}>Our Team</p> */}

                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="https://blogs.innerpece.com/"
                  target="blank"
                >
                  Blog Insights
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white service_12 text-center">Quick Links</h4>
              <div className="footer_col2_text text-center">
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p className="mt-3">Contact</p>
                </Link>
                <Link
                  to="/privacypolicy"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p>Privacy Policy</p>
                </Link>
               
                <Link
                  to="/termsandservice"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p className="mb-2">Terms Of Service</p>
                </Link>
              </div>
            </div>
            {footercontent && (
              <div className="col-lg-3 col-md-6  float-start mtext-center">
                {/* <h1>
                <img src={getin} alt="#" className="img-fluid get_in_touch_img" />
                <span className="get_in_touch">Get In Touch</span>
              </h1> */}
                <div className="mt-5 div_ul d-flex justify-content-center">
                  <ul className="list-inline col-md-12">
                    <li className="list-inline-item ">
                      <a href={footercontent.facebook} target="blank">
                        <img
                          src={fff1}
                          className="footer_col3_imges img-fluid"
                          alt="fff1"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={footercontent.twitter_x} target="blank">
                        <img
                          src={fffff3}
                          className="footer_col4_imges footer_col3_imges img-fluid"
                          alt="fff2"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={footercontent.linkedin} target="blank">
                        <img
                          src={fff3}
                          className="footer_col3_imges img-fluid"
                          alt="fff3"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={footercontent.instagram} target="blank">
                        <img
                          src={fff4}
                          className="footer_col3_imges img-fluid"
                          alt="fff4"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-3 foot_logo_122">
                  <img
                    src={`https://backoffice.innerpece.com/${footercontent.official_footer_logo}`}
                    alt="Footer_logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            )}
          </div>
          <hr className="footer_line" />

          <div className="row d-flex mtext-center justify-content-between">
            {footercontent && (
              <div className="col-lg-8 col-md-6 col-sm-12">
                {" "}
                <p className="Copyright">{footercontent.copyright}</p>
              </div>
            )}
            <div className="col-lg-4 col-md-6 col-sm-12">
              <p className="Copyright text-end mtext-center">
                Powered by{" "}
                <a href="https://aryutechnologies.com/" target="_blank">
                  Aryu Technologies
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
