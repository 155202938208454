import React, { useState, useEffect } from "react";
import "../../../assets/css/Pages/villas.css";
import star from "../../../assets/img/star.svg";
import Calender from "../../../assets/img/Calender.svg";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import moment from "moment";
import villa1 from "../../../assets/img/villa/villa1.svg";
import package2 from "../../../assets/img/WesternGhats/img1.png";
import package3 from "../../../assets/img/WesternGhats/img3.png";
import package4 from "../../../assets/img/WesternGhats/img4.png";
import Vector from "../../../assets/img/villa/Vector.svg";
import couple from "../../../assets/img/villa/couple 1.svg";
import baby from "../../../assets/img/villa/baby-boy 1.svg";
import pool from "../../../assets/img/villa/pool 1.svg";
import grass from "../../../assets/img/villa/grass 1.svg";
import bench from "../../../assets/img/villa/Group (2).svg";
import meal from "../../../assets/img/villa/meal 1.svg";
import theater from "../../../assets/img/villa/home-theater 1.svg";
import bed from "../../../assets/img/villa/bed.svg";
import {
  FaArrowRight,
  FaChevronLeft,
  FaChevronRight,
  FaLocationDot,
} from "react-icons/fa6";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import love from "../../../assets/img/villa/love.svg";
import "../../../assets/css/Pages/WesternGhats.css";
import FilterBox from "./Filterbox";
import useScreenSize from "./useScreenSize";
import { Link } from "react-router-dom";

export default function WesternGhats() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const [value, setValue] = useState(3000);
  const minValue = 3000;
  const maxValue = 25000;

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const [villa, setVillas] = useState([]);
  const [date, setDate] = useState("");
  const [startValue, setStartValue] = useState(25);
  const [endValue, setEndValue] = useState(75);
  const [currentSlide, setCurrentSlide] = useState([0, 0]);
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useScreenSize();

  const villasPerPage = 4;

  const indexOfLastVilla = currentPage * villasPerPage;
  const indexOfFirstVilla = indexOfLastVilla - villasPerPage;
  const currentVillas = villa.slice(indexOfFirstVilla, indexOfLastVilla);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(villa.length / villasPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleFilterChange = (filterType, value) => {
    console.log(`Filter changed: ${filterType} = ${value}`);
  };

  const handleDateChange = (selectedDates) => {
    const selectedDate = selectedDates[0];
    setDate(moment(selectedDate).format("DD/MM/YYYY"));
  };

  const handleSlideChange = (index, slideIndex) => {
    const newSlide = [...currentSlide];
    newSlide[index] = slideIndex;
    setCurrentSlide(newSlide);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--start-value",
      `${startValue}%`
    );
    document.documentElement.style.setProperty("--end-value", `${endValue}%`);
  }, [startValue, endValue]);

  const handleStartChange = (e) => {
    const value = parseInt(e.target.value);
    if (value < endValue) {
      setStartValue(value);
    }
  };

  const handleEndChange = (e) => {
    const value = parseInt(e.target.value);
    if (value > startValue) {
      setEndValue(value);
    }
  };

  const handleReset = () => {
    setStartValue(25);
    setEndValue(75);
    setDate(new Date());
    // Add logic to reset other filters if needed
  };

  const villas = [
    {
      name: "Nilgiri's Allure",
      location: "Chennai, Tamil Nadu",
      images: [villa1, villa1, package2, package3, package4],
      rating: 5,
      guests: 12,
      rooms: 4,
      baths: 5,
      price: "₹16,000",
      amenities: [
        { icon: pool, text: "Swimming Pool" },
        { icon: grass, text: "Lawn" },
        { icon: bench, text: "Beach View" },
        { icon: meal, text: "Meals" },
        { icon: theater, text: "Home Theater" },
      ],
      description: "RATED BEST FOR ITS AMENITIES AND SERVICE",
      blurContentTitle: "About 1st villa",
      blurContentPara:
        "Located in the French Capital of India, Chitrita Bhavan offers a peek into the yesteryears. Impeccably designed with heritage furniture, this beautiful villa is situated in the heart of the city. Featuring a sit-out alongside the in-house, artificial fountain, a library filled with an arra...",
    },
    {
      name: "Sunrise Villa",
      location: "ECR, Chennai",
      images: [package2, package2, package3, package4, villa1],
      rating: 4.5,
      guests: 10,
      rooms: 3,
      baths: 4,
      price: "₹12,000",
      amenities: [
        { icon: pool, text: "Swimming Pool" },
        { icon: grass, text: "Lawn" },
        { icon: bench, text: "Beach View" },
        { icon: meal, text: "Meals" },
        { icon: theater, text: "Home Theater" },
      ],
      description: "POPULAR FOR ITS VIEW AND AMBIENCE",
      blurContentTitle: "About 2nd villa",
      blurContentPara:
        "Located in the French Capital of India, Chitrita Bhavan offers a peek into the yesteryears. Impeccably designed with heritage furniture, this beautiful villa is situated in the heart of the city. Featuring a sit-out alongside the in-house, artificial fountain, a library filled with an arra...",
    },
    {
      name: "Nilgiri's Allure",
      location: " Chennai, Tamil Nadu",
      images: [villa1, villa1, package2, package3, package4],
      rating: 5,
      guests: 12,
      rooms: 4,
      baths: 5,
      price: "₹16,000",
      amenities: [
        { icon: pool, text: "Swimming Pool" },
        { icon: grass, text: "Lawn" },
        { icon: bench, text: "Beach View" },
        { icon: meal, text: "Meals" },
        { icon: theater, text: "Home Theater" },
      ],
      description: "RATED BEST FOR ITS AMENITIES AND SERVICE",
      blurContentTitle: "About 3rd villa",
      blurContentPara:
        "Located in the French Capital of India, Chitrita Bhavan offers a peek into the yesteryears. Impeccably designed with heritage furniture, this beautiful villa is situated in the heart of the city. Featuring a sit-out alongside the in-house, artificial fountain, a library filled with an arra...",
    },
    {
      name: "Sunrise Villa",
      location: " ECR, Chennai",
      images: [package2, package2, package3, package4, villa1],
      rating: 4.5,
      guests: 10,
      rooms: 3,
      baths: 4,
      price: "₹12,000",
      amenities: [
        { icon: pool, text: "Swimming Pool" },
        { icon: grass, text: "Garden" },
        { icon: bench, text: "Sea View" },
        { icon: meal, text: "BBQ" },
        { icon: theater, text: "Home Theater" },
      ],
      description: "POPULAR FOR ITS VIEW AND AMBIENCE",
      blurContentTitle: "About 4th villa",
      blurContentPara:
        "Located in the French Capital of India, Chitrita Bhavan offers a peek into the yesteryears. Impeccably designed with heritage furniture, this beautiful villa is situated in the heart of the city. Featuring a sit-out alongside the in-house, artificial fountain, a library filled with an arra...",
    },
  ];

  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      disabled={!hasPrev}
      className="villa-custom-prev"
      aria-label={label}
    >
      <FaChevronLeft className="chevron-left" />
    </button>
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      disabled={!hasNext}
      className="villa-custom-next"
      aria-label={label}
    >
      <FaChevronRight className="chevron-right" />
    </button>
  );
  return (
    <div className="d-flex flex-column justify-content-between western-container">
      <div className="western-content">
        <div className="WesternSidebar">
          <div className="Western-Navigation-bar">
            <p className="hmmtr">Home &gt; Western Ghats</p>
          </div>

          <div className="titleBackground">
            <div className="banner" id="banner1">
              <div className="banner-title">Western Ghats</div>
              <h6 className="banner-desc">
                Lorem ipsum dolor sit amet consectetur. Sed egestas mauris
                ornare amet egestas.
              </h6>
              <div className="inputContainer">
                <input
                  type="text"
                  placeholder="Search Packages"
                  className="input"
                />
                <div className="search-btn">Search</div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end sorts">
          <div className="sorts">
            <p className="sort-btn">Sort By : Most Loved</p>
          </div>
        </div>
        <div className="d-flex justify-content-between row">
          {isMobile ? (
            <div className="col-md-12 mt-2 ms-2">
              <FilterBox />
            </div>
          ) : (
            <>
              <div className="col-lg-2 col-md-2 col-sm-12">
                <div>
                  <div className="me-2 mt-2">
                    <div className="d-flex justify-content-between align-items-center p-4 filter-header">
                      <div className="font21">Filters</div>
                      <div className="resetbtn shadow-sm" onClick={handleReset}>
                        Reset
                      </div>
                    </div>
                    <div className="category-box p-4">
                      <h6 className="subtitle">Category</h6>
                      {[
                        "Promo Deals",
                        "One Day Trip",
                        "Top Vacation",
                        "Things To do",
                      ].map((category, index) => (
                        <div key={index} className="d-flex my-3">
                          <input
                            type="checkbox"
                            className="form-check-input border-checkbox"
                            onClick={() =>
                              handleFilterChange("category", category)
                            }
                          />
                          <div
                            onClick={() =>
                              handleFilterChange("category", category)
                            }
                            className="ps-2 category_text"
                          >
                            {category}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="p-3 mt-2 mb-4">
                      <h6 className="subtitle">Date</h6>
                      <div className="date_section d-flex">
                        <div className="label d-flex align-items-center justify-content-center">
                          <img
                            className="label-date"
                            src={Calender}
                            alt="Calender"
                          />
                        </div>
                        <Flatpickr
                          className="inputs"
                          placeholder="DD/MM/YYYY"
                          value={date}
                          onChange={handleDateChange}
                          options={{
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d-m-Y",
                            allowInput: true,
                            parseDate: (datestr, format) =>
                              moment(datestr, format, true).toDate(),
                            formatDate: (date, format) =>
                              moment(date).format(format),
                          }}
                        />
                      </div>
                    </div>
                    <div className="p-3 mt-2 mb-3">
                      <h6 className="subtitle" id="filter_left">
                        Filter by Price
                      </h6>
                      <div className="col-11">
                        <input
                          type="range"
                          className="form-range"
                          id="amountRange"
                          min={minValue}
                          max={maxValue}
                          value={value}
                          step="100"
                          onChange={handleInputChange}
                          style={{
                            WebkitAppearance: "none",
                            width: "100%",
                            height: "8px",
                            background: `linear-gradient(to right, #007bff ${
                              ((value - minValue) / (maxValue - minValue)) * 100
                            }%, #ddd ${
                              ((value - minValue) / (maxValue - minValue)) * 100
                            }%)`,
                            borderRadius: "5px",
                            outline: "none",
                          }}
                        />
                        <label
                          htmlFor="amountRange"
                          className="form-label mt-3"
                        >
                          Price:{" "}
                          <span className="range-value">
                            ₹{minValue} - ₹{value}
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="p-3">
                      <h6 className="subtitle">Rating</h6>
                      {[5, 4, 3, 2, 1].map((rating, index) => (
                        <div key={index} className="d-flex my-3">
                          <input
                            type="checkbox"
                            className="form-check-input border-checkbox"
                            onClick={() => handleFilterChange("rating", rating)}
                          />
                          <img
                            src={star}
                            alt="star"
                            onClick={() => handleFilterChange("rating", rating)}
                          />
                          <div
                            onClick={() => handleFilterChange("rating", rating)}
                          >
                            {" "}
                            {rating} Star
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="p-3 activity-box">
                      <h6 className="subtitle">Activity</h6>
                      {["Beach", "Club", "Cultural", "Natural Tourism"].map(
                        (activity, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-start my-3"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-checkbox"
                              onClick={() =>
                                handleFilterChange("activity", activity)
                              }
                            />
                            <div
                              className="px-2"
                              onClick={() =>
                                handleFilterChange("activity", activity)
                              }
                            >
                              {activity}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-lg-10 col-md-10 col-sm-12">
            {villas.map((villa, index) => (
              <div key={index} className="bg">
                <div className="d-flex flex-column card-container">
                  <div className="d-flex justify-content-between row border">
                    <div className="col-lg-4 col-md-12 villa-img">
                      <Carousel
                        renderArrowPrev={renderArrowPrev}
                        renderArrowNext={renderArrowNext}
                        infiniteLoop
                        useKeyboardArrows
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={true}
                        selectedItem={currentSlide[index]}
                        onChange={(slideIndex) =>
                          handleSlideChange(index, slideIndex)
                        }
                      >
                        {villa.images.map((imgSrc, imgIndex) => (
                          <div key={imgIndex} className="Westerngharts_23">
                            {imgIndex === 1 ? ( // Check if it's the second image
                              <div className="position-relative">
                                <div className="Westerngharts_23">
                                  <img
                                    src={imgSrc}
                                    alt={`Slide ${imgIndex + 1}`}
                                    className="d-block w-100 "
                                  />
                                </div>
                                <div
                                  className="position-absolute top-0 start-0 w-100 h-100"
                                  style={{
                                    backdropFilter: "blur(8px)",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  }}
                                ></div>
                                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                                  {/* Content to display on top of the blurred image */}
                                  <div className="text-center text-white p-4">
                                    <h2 className="villa-blurimage-title">
                                      {villa.blurContentTitle}
                                    </h2>
                                    <p className="villa-blurimage-para">
                                      {villa.blurContentPara}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <img
                                src={imgSrc}
                                alt={`Slide ${imgIndex + 1}`}
                                className="d-block w-100 Westerngharts_23"
                              />
                            )}
                            <img src={love} className="love-icon" alt="love" />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="package-column2">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="Villa_1 ">{villa.name}</h5>
                            <div className="d-flex align-items-center ">
                              <div className="col-1 mb-2">
                                <FaLocationDot color="#0F5B92" />
                              </div>
                              <div className="col-11 ms-2">
                                <p className="Villa_2 mt-2">{villa.location}</p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <img src={star} alt="star" className="star-icon " />
                            <div className="d-flex align-items-center">
                              <h4>{villa.rating}</h4>
                              <div> of 5</div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex Villa_3  justify-content-evenly ">
                          <div>Upto {villa.guests} Guests</div>
                          <img
                            src={Vector}
                            alt="vector"
                            className="vector-icon"
                          />
                          {villa.rooms} Rooms
                          <img
                            src={Vector}
                            alt="vector"
                            className="vector-icon"
                          />
                          {villa.baths} Baths
                        </div>
                        <div>
                          <div className="d-flex Villa_4 justify-content-evenly mt-3">
                            <div>Great for:</div>
                            <div className="d-flex justify-content-between ms-3">
                              <img src={couple} alt="icon" />
                              &nbsp;
                              <div> Senior Citizen</div>
                            </div>
                            <div className="d-flex justify-content-between ms-1">
                              <img src={baby} alt="baby" />
                              &nbsp;
                              <div> Kids</div>
                            </div>
                          </div>
                        </div>
                        {isMobile ? "" : <div className="line"></div>}
                        <div className="d-flex mt-4 p-2">
                          {villa.amenities.map((item, itemIndex) => (
                            <div
                              key={itemIndex}
                              className="d-flex flex-column align-items-center justify-content-center img_cont_icon"
                            >
                              <div>
                                <img
                                  className="icon"
                                  src={item.icon}
                                  alt={item.text}
                                />
                              </div>
                              <div className="small-text">{item.text}</div>
                            </div>
                          ))}
                          <div className="d-flex mt-3">
                            <div className="grey-text">20+</div>
                          </div>
                        </div>
                        {isMobile ? <div className="border-bottom"></div> : ""}
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12 sm-3 price-container">
                      <div className="mt-5 col-md-12 d-flex flex-column align-items-center price ">
                        <div className="price-text7">{villa.price}</div>
                        <div className="d-flex align-items-center justify-content-center border border-3 rounded p-1 mt-3  rooms_bed">
                          <img src={bed} alt="bed" />
                          <div className="">For {villa.rooms} Rooms</div>
                        </div>
                        <div className="col-4-text mt-3">
                          for 32 Nights + Taxes({villa.rooms} rooms)
                        </div>
                        <Link style={{ textDecoration: "none" }} to="/sample">
                          <div className="d-flex align-items-center justify-content-center view-btn mt-3">
                            <div>View</div>
                            <div className="ms-2 ">
                              <FaArrowRight />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="footer">{villa.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between mt-5 p-5">
          <div className="col-lg-9 mt-2 ms-2">
            {/* {currentVillas.map((villa, index) => (
            <div key={index} className='bg'>
            </div>
          ))} */}
            <div className="pagination">
              <ul className="pagination-list">
                {pageNumbers.map((number) => (
                  <li key={number} className="page-item">
                    <button
                      onClick={() => paginate(number)}
                      className="page-link"
                    >
                      {number}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//   {isMobile ? (
//     <div className="col-md-12 mt-2 ms-2">
//       <Filterbox />
//     </div>
//   ) : ( <>
// <div className='col-lg-3 col-md-12 col-sm-12'>
//   <div>
//     <div className='me-2 mt-2'>
//       <div className='d-flex justify-content-between align-items-center p-3 filter-header'>
//         <div className="font21">Filters</div>
//         <div className='resetbtn' onClick={handleReset}>Reset</div>
//       </div>
//       <div className='category-box p-3'>
//         <h6 className="subtitle">Category</h6>
//         {['Promo Deals', 'One Day Trip', 'Top Vacation', 'Things To do'].map((category, index) => (
//           <div key={index} className="d-flex my-1">
//             <input type='checkbox' className="form-check-input border-checkbox" onClick={() => handleFilterChange('category', category)} />
//             <div onClick={() => handleFilterChange('category', category)} className='ps-2'>{category}</div>
//           </div>
//         ))}
//       </div>
//       <div className='p-3'>
//         <h6 className="subtitle">Filter by Price</h6>
//         <div className="dual-handle-progress-container">
//           <input
//             type="range"
//             min="0"
//             max="100"
//             step="1"
//             value={startValue}
//             onChange={handleStartChange}
//             className="progress-bar start"
//           />
//           <input
//             type="range"
//             min="0"
//             max="100"
//             step="1"
//             value={endValue}
//             onChange={handleEndChange}
//             className="progress-bar end"
//           />
//           <div className="progress-value start">{startValue}%</div>
//           <div className="progress-value end">{endValue}%</div>
//         </div>
//       </div>
//       <div className='p-3'>
//         <h6 className="subtitle">Date</h6>
//         <div className="d-flex">
//           <div className='label d-flex align-items-center justify-content-center'>
//             <img className='label-date' src={Calender} alt='Calender' />
//             </div>
//             <Flatpickr
//               className="inputs"
//               placeholder="DD/MM/YYYY"
//               value={date}
//               onChange={handleDateChange}
//               options={{
//                 altInput: true,
//                 dateFormat: 'Y-m-d',
//                 altFormat: 'd-m-Y',
//                 allowInput: true,
//                 parseDate: (datestr, format) => moment(datestr, format, true).toDate(),
//                 formatDate: (date, format) => moment(date).format(format),
//               }}
//             />
//           </div>
//         </div>
//         <div className='p-3'>
//           <h6 className="subtitle">Rating</h6>
//           {[5, 4, 3, 2, 1].map((rating, index) => (
//             <div key={index} className="d-flex my-2">
//               <input type='checkbox' className="form-check-input border-checkbox" onClick={() => handleFilterChange('rating', rating)} />
//               <img src={star} alt='star' onClick={() => handleFilterChange('rating', rating)} />
//               <div onClick={() => handleFilterChange('rating', rating)}> {rating} Star</div>
//             </div>
//           ))}
//         </div>
//         <div className='p-3 activity-box'>
//           <h6 className="subtitle">Activity</h6>
//           {['Beach', 'Club', 'Cultural', 'Natural Tourism'].map((activity, index) => (
//             <div key={index} className="d-flex justify-content-start my-2">
//               <input type='checkbox' className="form-check-input border-checkbox" onClick={() => handleFilterChange('activity', activity)} />
//               <div className='px-2' onClick={() => handleFilterChange('activity', activity)}>{activity}</div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   </div>
//   </>)}
