import React from "react";
import { Link } from "react-router-dom";
// import arrow_icon from "../../../assets/svg/arrow_icon.svg";

const Section_2 = () => {
  return (
    <div className="container-fluid mt-5">
      <div className="bg-image d-flex align-items-center justify-content-center">
        <div className="container-fluid text-container">
          <div className="row">
            <div className="col-md-4">
              <h4 className="text-start Bulk1 poppins">GROUP</h4>
              <h1 className="text-start Booking1 poppins">BOOKING</h1>
              <div className="text-start">
                <Link to="contact">
                  <a className="btn event_btn1 text-center" href="#">
                    <span id="event_btn_txt" className="text-center">
                      EXPLORE MORE
                    </span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_2;
