import React from "react";
import background from "../../../assets/img/detail/detail_bg.svg";
import human1 from "../../../assets/img/detail/detail_1.svg";
import img1 from "../../../assets/img/detail/detail_img1.svg";
import img2 from "../../../assets/img/detail/detail_img2.svg";
import pot1 from "../../../assets/img/detail/potation1.svg";
import pot2 from "../../../assets/img/detail/potation2.svg";
import comment1 from "../../../assets/img/detail/comment1.svg";
import comment2 from "../../../assets/img/detail/comment2.svg";
import comment3 from "../../../assets/img/detail/comment3.svg";
import comment4 from "../../../assets/img/detail/comment4.svg";
import relatedblogs1 from "../../../assets/img/detail/relatedblogs1.svg";
import relatedblogs2 from "../../../assets/img/detail/relatedblogs2.svg";
import relatedblogs3 from "../../../assets/img/detail/relatedblogs3.svg";


// import BlogCard from './BlogCard';
function Section1() {
  return (
    <div>
      <div className="detail_background">
        <img src={background} className="detail_image mb-4 c_img" />
      </div>
      <div className="container mt-4">
        <div>
          <h1 className="detail_h mb-5 text-start">
            MY TRAVEL GUIDE TO VARANASI, INDIA’S HOLIEST CITY
          </h1>
          <div className="container">
            <div className="my-3 ms-4 mobile_views">
              <p className="detail_p md-4 ms-3">
                Orci varius natoque penatibus et magnis dis parturient montes,
                nascetur ridiculus mus. Etiam placerat tortor <br></br>commodo
                lectus laoreet venenatis. Donec ultricies, metus vitae bibendum
                consequat, tortor neque euismod <br></br>lectus
              </p>
              <div class="author-info">
                <div class="author-details">
                  <img src={human1} alt="Author" />
                  <div class="author-name">
                    <strong className="author-names">Lance Bogrol</strong>
                    <div class="author-pub-date">Published on Dec 01, 2024</div>
                  </div>
                </div>
                <div className="detail_list">
                  <ul className="detail_ul">
                    <li className="detail_li">
                      <p className="Share">Share:</p>
                    </li>
                    <li className="detail_li">
                      <i class="bi bi-facebook"></i>
                    </li>
                    <li className="detail_li">
                      <i class="bi bi-twitter-x"></i>
                    </li>
                    <li className="detail_li">
                      <i class="bi bi-linkedin"></i>
                    </li>
                    <li className="detail_li">
                      {" "}
                      <i class="bi bi-instagram"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className="detail_p md-4 ms-2 mt-5">
                  Quisque at odio semper, elementum leo sed, congue tellus.
                  Proin nunc mauris, porttitor ut eleifend ut,<br></br>{" "}
                  consectetur ut dolor. In hac habitasse platea dictumst.
                  Pellentesque ornare nulla ut quam blandit scelerisque.
                  <br></br> Suspendisse non orci id elit tempor rhoncus ac id
                  nunc. Integer scelerisque at turpis sit amet faucibus. Etiam{" "}
                  <br></br>non euismod urna. Suspendisse vel ex justo. Vivamus
                  posuere porttitor ante eu hendrerit.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="detail_images">
          <img src={img1} alt="#" className="detail_images12" />
          <div className="container mt-5">
            <div className="my-3 ms-4 mobile_views">
              <p className="detail_p md-4 ms-2 mt-5">
                In lacinia sapien a libero accumsan facilisis. Donec vitae lorem
                massa. Aliquam tristique vehicula enim ut <br></br>luctus.
                Vivamus gravida dignissim ligula, dictum laoreet elit malesuada
                ac. Praesent est justo, posuere a nisl<br></br> porta, pharetra
                posuere lectus. Nulla velit odio, tincidunt vel metus a, viverra
                placerat ligula.<br></br> <br></br>
                Donec id nisl et risus volutpat tempor a eget mauris. Nullam
                velit eros, porttitor et urna sit amet, ullamcorper<br></br>{" "}
                vestibulum magna. Quisque consequat arcu eros, lobortis faucibus
                purus facilisis vitae. Nulla at nunc non<br></br> purus vehicula
                elementum.
              </p>
            </div>

            <div className="row ms-5 pot_row">
              {/* <div className="col-2 pot_col2">
                <img src={pot1}></img>
              </div> */}
              <div className="col-10 pot_col10">
                {/* <blockquote className="blockquote"> */}
                  <p className="pot_cont mb-1">
                    Suspendisse sagittis id lacus eget vulputate. Ut nec arcu ut
                    sem molestie tincidunt luctus eget tellus.
                  </p>
                  <footer className="mt-3 text-center pot_p">
                    Parsley Montana
                  </footer>
                  {/* <div className="pot_2 text-end">
                    <img src={pot2} alt="#" className="pot_22"></img>
                  </div> */}
                {/* </blockquote> */}
              </div>
            </div>
            <div className="big_head1">
              <h5 className="big_head">Big heading for a new topic</h5>
            </div>
            <div className="ms-4 mobile_views">
              <p className="detail_p md-4 ms-2 mt-4">
                Morbi pellentesque finibus libero, in blandit lorem eleifend
                eget. Praesent egestas hendrerit augue a <br></br>vestibulum.
                Nullam fringilla, eros malesuada eleifend placerat, lacus tellus
                egestas erat, nec varius sem lorem<br></br> ut mauris. Morbi
                libero felis.
              </p>
              <div>
                <ul>
                  <li className="detail_p mt-5">
                    Morbi pellentesque finibus libero, in blandit lorem eleifend
                    eget. Praesent egestas hendrerit augue a vestibulum. Nullam
                    fringilla, eros malesuada eleifend placerat, lacus tellus
                    egestas erat, nec varius sem lorem ut mauris. Morbi libero
                    felis.
                  </li>
                  <li className="detail_p mt-5">
                    Cras eget dolor accumsan, blandit risus vitae, faucibus
                    erat. Aliquam scelerisque, diam ut feugiat scelerisque, diam
                    felis venenatis purus, eget cursus enim turpis at sem. Fusce
                    nec tristique dolor, sit amet tristique purus.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="detail_images">
          <img src={img2} alt="#" className="detail_images12" />
          <div className="container mt-5">
            <div className="my-3 ms-4 mobile_views">
              <p className="detail_p md-4 ms-2 mt-5">
                Quisque at odio semper, elementum leo sed, congue tellus. Proin
                nunc mauris, porttitor ut eleifend ut,<br></br> consectetur ut
                dolor. In hac habitasse platea dictumst. Pellentesque ornare
                nulla ut quam blandit scelerisque.<br></br>Suspendisse non orci
                id elit tempor rhoncus ac id nunc. Integer scelerisque at turpis
                sit amet faucibus. Etiam<br></br> non euismod urna.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="mt-5 mb-5 text-center comm">Comments</h2>

        <div className="comment-box d-flex align-items-start mb-4">
          <img src={comment1} alt="Avatar" />
          <div className="ms-5 ml-3">
            <h5 className="commenter_name">Hans Down</h5>
            <small className="commenter_date">01 Dec 2024</small>
            <p className="details_comments">
              Praesent ut fringilla ligula. Vivamus et lacus nec risus malesuada
              vestibulum. Phasellus lobortis viverra lobortis. Donec iaculis,
              erat eu bibendum faucibus.
            </p>
          </div>
          <div className="ml-auto">
            <button className="comment_reply_btn">
              <i class="bi bi-reply-fill reply_icon"></i>
              <span className="button_reply">Reply</span>
            </button>
          </div>
        </div>
        <hr className="comment_underline"></hr>

        <div className="comment-box d-flex align-items-start mb-3">
          <img src={comment2} alt="Avatar" />
          <div className="ms-5 ml-3">
            <h5 className="commenter_name">Penny Tool</h5>
            <small className="commenter_date">01 Dec 2024</small>
            <p className="details_comments">
              Praesent ut fringilla ligula. Vivamus et lacus nec risus malesuada
              vestibulum. Phasellus lobortis viverra lobortis. Donec iaculis,
              erat eu bibendum faucibus.
            </p>
          </div>
          <div className="ml-auto">
            <button className="comment_reply_btn">
              <i class="bi bi-reply-fill reply_icon"></i>
              <span className="button_reply">Reply</span>
            </button>
          </div>
        </div>

        <div className="comment-box d-flex align-items-start ms-5">
          <img src={comment3} alt="Avatar" />
          <div className="ms-5 ml-3">
            <h5 className="commenter_name">Eric Widget</h5>
            <small className="commenter_date">01 Dec 2024</small>
            <p className="details_comments">
              Praesent ut fringilla ligula. Vivamus et lacus nec risus malesuada
              vestibulum. Phasellus lobortis viverra lobortis.
            </p>
          </div>
          <div className="ml-auto">
            <button className="comment_reply_btn">
              <i class="bi bi-reply-fill reply_icon"></i>
              <span className="button_reply">Reply</span>
            </button>
          </div>
        </div>
        <hr className="comment_underline"></hr>

        <div className="comment-box d-flex align-items-start mb-4">
          <img src={comment4} alt="Avatar" />
          <div className="ms-5 ml-3">
            <h5 className="commenter_name">Penny Tool</h5>
            <small className="commenter_date">01 Dec 2024</small>
            <p className="details_comments">
              Praesent ut fringilla ligula. Vivamus et lacus nec risus malesuada
              vestibulum. Phasellus lobortis viverra lobortis. Donec iaculis,
              erat eu bibendum faucibus.
            </p>
          </div>
          <div className="ml-auto">
            <button className="comment_reply_btn">
              <i class="bi bi-reply-fill reply_icon"></i>
              <span className="button_reply">Reply</span>
            </button>
          </div>
        </div>
        <hr className="comment_underline"></hr>
      </div>

      <div className="container">
        <div className="form-container mt-5">
          <h2>Post a Comment</h2>
          <form>
            <div className="form_content">
              <label for="name" className="comment_form_name mb-2">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name, surname"
              />
            </div>
            <div className="form_content">
              <label for="email" className="comment_form_name mb-2">
                Email
              </label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Email address"
              />
            </div>
            <div className="form_content">
              <label for="comment" className="comment_form_name mb-2">
                Comment
              </label>
              <textarea
                className="form-control"
                id="comment"
                rows="3"
                placeholder="Your comment"
              ></textarea>
            </div>
            <div className="form_submit_btn">
              <button type="submit" class="btn btn-primary comment_form_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="container">
      <h2 className="text-center mb-4 RelatedBlogs">Related Blogs</h2>
      <hr className="gap-4"></hr>
      <div className="row g-4">
        <div className="col-12 col-sm-6 col-md-4">
          <div className="card shadow-sm h-100">
            <img src={relatedblogs1} className="card-img-top rounded" alt="Blog" />
            <div className="card-body">
              <p className="card-text">
                <small className="text-muted">December 1, 2024</small>
              </p>
              <h5 className="card-title">
                Sollicitudin vitae scelerisque quis nunc. Nunc felis aliquam sit pellentesque morbi.
              </h5>
              <p className="card-text">
                Integer at suspendisse elementum sit ut donec adipiscing. Facilisis ultrices aliquet scelerisque purus commodo quis elementum. Eu id tristique.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="card shadow-sm h-100">
            <img src={relatedblogs2} className="card-img-top rounded" alt="Blog" />
            <div className="card-body">
              <p className="card-text">
                <small className="text-muted">December 1, 2024</small>
              </p>
              <h5 className="card-title">
                Sollicitudin vitae scelerisque quis nunc. Nunc felis aliquam sit pellentesque morbi.
              </h5>
              <p className="card-text">
                Integer at suspendisse elementum sit ut donec adipiscing. Facilisis ultrices aliquet scelerisque purus commodo quis elementum. Eu id tristique.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <div className="card shadow-sm h-100">
            <img src={relatedblogs3} className="card-img-top rounded" alt="Blog" />
            <div className="card-body">
              <p className="card-text">
                <small className="text-muted">December 1, 2024</small>
              </p>
              <h5 className="card-title">
                Sollicitudin vitae scelerisque quis nunc. Nunc felis aliquam sit pellentesque morbi.
              </h5>
              <p className="card-text">
                Integer at suspendisse elementum sit ut donec adipiscing. Facilisis ultrices aliquet scelerisque purus commodo quis elementum. Eu id tristique.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Section1;
