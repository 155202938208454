import React, { useState } from "react";
import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../assets/css/Userlogin/userlogin.css";
import Usersignup_2 from "../../../assets/img/Userlogin/Usersignup_2.svg";
// import Arrow_1 from "../../../assets/img/Userlogin/Arrow_1.svg";
// import Arrow_2 from "../../../assets/img/Userlogin/Arrow_2.svg";
// import Arrow_3 from "../../../assets/img/Userlogin/Arrow_3.svg";
// import Arrow_4 from "../../../assets/img/Userlogin/Arrow_4.svg";

// const RECAPTCHA_SITE_KEY = "your-site-key"; // Replace with your reCAPTCHA site key

const Login = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordconfirmation, setPasswordConfirmation] = useState("");
  const [dob, setDob] = useState("");
  const [preferredLang, setPreferredLang] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipprovincecode, setZipprovincecode] = useState("");
  const [newslettersub, setNewslettersub] = useState(false);
  const [termscondition, setTermscondition] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");

  

  const validate = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "First name is required.";
    if (!lastName) newErrors.lastName = "Last name is required.";
    if (!email) {
      newErrors.email = "Email address is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    if (!password) newErrors.password = "Password is required.";
    if (password !== passwordconfirmation) {
      newErrors.passwordconfirmation = "Passwords do not match.";
    }
    if (!dob) newErrors.dob = "Date of birth is required.";
    if (!preferredLang) {
      newErrors.preferredLang = "Preferred language is required.";
    }
    if (!street || !city || !state || !zipprovincecode || !country) {
      newErrors.address = "Complete address is required."; // Fix here
    }
    if (!termscondition) {
      newErrors.termsAccepted = "You must accept the terms and conditions.";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setErrors({});
    setSuccess("");

    try {
      const response = await axios.post(
        " https://backoffice.innerpece.com/api/signup",
        {
          first_name: firstName, // Ensure the field names match API expectations
          last_name: lastName,
          email,
          phone,
          password,
          password_confirmation: passwordconfirmation,
          dob,
          preferred_lang: preferredLang,
          street,
          city,
          state,
          country,
          zip_province_code: zipprovincecode,
          newsletter_sub: newslettersub,
          terms_condition: termscondition,
          // captcha,
        }
      );

      setSuccess("Signup successful! Please click login.");
      // Clear form fields or redirect as needed
      console.log(response);
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setErrors({
        server: error.response?.data?.message || "Please try again.",
      });
    }
  };
 
  return (
    <div>
      {/* Header Section */}
      <header className="header-section_34">
        <div className="overlay_33">
          {/* <div>
            <img src={Arrow_1} alt="Arrow 1" />
            <img src={Arrow_2} className="flights_3" alt="Arrow 2" />
          </div> */}
          <div className="col-12 text-center">
            <h1 className="header-title_33">User Sign Up</h1>
            <nav aria-label="breadcrumb_33">
              <ol className="breadcrumb justify-content-center">
                <p>
                  <a href="/">Home</a> / User Registration
                </p>
              </ol>
            </nav>
          </div>
          {/* <div>
            <img src={Arrow_4} className="flights_3" alt="Arrow 4" />
            <img src={Arrow_3} alt="Arrow 3" />
          </div> */}
        </div>
      </header>

      {/* Login Form Section */}
      <div className="login-page_33">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-10">
              <div className="card_33 shadow-lg border-0 rounded-lg mt-5">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-lg-4 p-3">
                      <img
                        src={Usersignup_2}
                        className="img-fluid rounded-start h-100 w-100 object-fit-cover"
                        alt="Scenic View"
                      />
                    </div>
                    <div className="col-lg-8 p-4">
                      <h3 className="text-start mt-2">
                        Create an account to get started
                      </h3>
                      <p className="text-start text-muted mt-4">
                        <span className="badge bg-warning text-dark">
                          20% off
                        </span>{" "}
                        get 20% off for web signup
                      </p>
                      {errors.server && (
                        <div className="alert alert-danger">
                          {errors.server}
                        </div>
                      )}
                      {success && (
                        <div className="alert alert-success">{success}</div>
                      )}
                      <form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="firstName" className="form-label">
                              First Name
                            </label>
                            <input
                              type="text"
                              className={`form-control p-3 ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              name="firstName"
                              onChange={(e) => setFirstName(e.target.value)}
                              id="firstName"
                              placeholder="Enter your first name"
                            />
                            {errors.firstName && (
                              <div className="invalid-feedback">
                                {errors.firstName}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 col-md-6  float-start">
                            <label htmlFor="lastName" className="form-label">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className={`form-control p-3 ${
                                errors.lastName ? "is-invalid" : ""
                              }`}
                              name="lastName"
                              onChange={(e) => setLastName(e.target.value)}
                              id="lastName"
                              placeholder="Enter your last name"
                            />
                            {errors.lastName && (
                              <div className="invalid-feedback">
                                {errors.lastName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="email" className="form-label">
                              Email Address
                            </label>
                            <input
                              type="email"
                              className={`form-control p-3 ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              name="email"
                              onChange={(e) => setEmail(e.target.value)}
                              id="email"
                              placeholder="Enter your email"
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="phone" className="form-label">
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              className={`form-control p-3 ${
                                errors.phone ? "is-invalid" : ""
                              }`}
                              name="phone"
                              onChange={(e) => setPhone(e.target.value)}
                              id="phone"
                              placeholder="Enter your phone number"
                            />
                            {errors.phone && (
                              <div className="invalid-feedback">
                                {errors.phone}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="password" className="form-label">
                              Create Password
                            </label>
                            <input
                              type="password"
                              className={`form-control p-3 ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              name="password"
                              onChange={(e) => setPassword(e.target.value)}
                              id="password"
                              placeholder="Create Password"
                            />
                            {errors.password && (
                              <div className="invalid-feedback">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 col-md-6 float-start">
                            <label
                              htmlFor="confirmPassword"
                              className="form-label"
                            >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className={`form-control p-3 ${
                                errors.confirmPassword ? "is-invalid" : ""
                              }`}
                              name="confirmPassword"
                              onChange={(e) =>
                                setPasswordConfirmation(e.target.value)
                              }
                              id="passwordConfirmation"
                              placeholder="Confirm Password"
                            />
                            {errors.confirmPassword && (
                              <div className="invalid-feedback">
                                {errors.confirmPassword}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="dob" className="form-label">
                              Date Of Birth
                            </label>
                            <input
                              type="date"
                              className={`form-control p-3 ${
                                errors.dob ? "is-invalid" : ""
                              }`}
                              name="dob"
                              onChange={(e) => setDob(e.target.value)}
                              id="dob"
                            />
                            {errors.dob && (
                              <div className="invalid-feedback">
                                {errors.dob}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="street" className="form-label">
                              Street
                            </label>
                            <input
                              type="text"
                              className={`form-control p-3 ${
                                errors.address?.street ? "is-invalid" : ""
                              }`}
                              name="street"
                              onChange={(e) => setStreet(e.target.value)}
                              id="street"
                              placeholder="Street"
                            />
                            {errors.address?.street && (
                              <div className="invalid-feedback">
                                {errors.address.street}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="city" className="form-label">
                              City
                            </label>
                            <input
                              type="text"
                              className={`form-control p-3 ${
                                errors.address?.city ? "is-invalid" : ""
                              }`}
                              name="city"
                              onChange={(e) => setCity(e.target.value)}
                              id="city"
                              placeholder="City"
                            />
                            {errors.address?.city && (
                              <div className="invalid-feedback">
                                {errors.address.city}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="state" className="form-label">
                              State/Province
                            </label>
                            <input
                              type="text"
                              className={`form-control p-3 ${
                                errors.address?.state ? "is-invalid" : ""
                              }`}
                              name="state"
                              onChange={(e) => setState(e.target.value)}
                              id="state"
                              placeholder="State/Province"
                            />
                            {errors.address?.state && (
                              <div className="invalid-feedback">
                                {errors.address.state}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="zip" className="form-label">
                              Zip/Postal Code
                            </label>
                            <input
                              type="text"
                              className={`form-control p-3 ${
                                errors.address?.zipprovincecode
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="zip"
                              onChange={(e) =>
                                setZipprovincecode(e.target.value)
                              }
                              id="zip"
                              placeholder="zipprovincecode/Postal Code"
                            />
                            {errors.address?.zip && (
                              <div className="invalid-feedback">
                                {errors.address.zipprovincecode}
                              </div>
                            )}
                          </div>
                          <div className="mb-3 col-md-6 float-start">
                            <label htmlFor="country" className="form-label">
                              Country
                            </label>
                            <input
                              type="text"
                              className={`form-control p-3 ${
                                errors.address?.country ? "is-invalid" : ""
                              }`}
                              name="country"
                              onChange={(e) => setCountry(e.target.value)}
                              id="country"
                              placeholder="Country"
                            />
                            {errors.address?.country && (
                              <div className="invalid-feedback">
                                {errors.address.country}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="preferredLang" className="form-label">
                            Preferred Language
                          </label>
                          <input
                            type="text"
                            className={`form-control p-3 ${
                              errors.preferredLang ? "is-invalid" : ""
                            }`}
                            name="preferredLang"
                            onChange={(e) => setPreferredLang(e.target.value)}
                            id="preferredLang"
                            placeholder="Preferred Language"
                          />
                          {errors.preferredLang && (
                            <div className="invalid-feedback">
                              {errors.preferredLang}
                            </div>
                          )}
                        </div>
                        <div className="form-check mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="newsletter"
                            checked={newslettersub}
                            onChange={() => setNewslettersub(!newslettersub)}
                          />
                          <label
                            htmlFor="newslettersub"
                            className="form-check-label"
                          >
                            Subscribe to Newsletter
                          </label>
                        </div>
                        <div className="form-check mb-3">
                          <input
                            type="checkbox"
                            className={`form-check-input ${
                              errors.termscondition ? "is-invalid" : ""
                            }`}
                            id="terms"
                            checked={termscondition}
                            onChange={() => setTermscondition(!termscondition)}
                          />
                        <label htmlFor="terms" className="form-check-label">
  I accept the
  <Link to='/termsandcondition' className="terms-link">
    Terms and Conditions
  </Link>
</label>

                          {errors.termscondition && (
                            <div className="invalid-feedback">
                              {errors.termscondition}
                            </div>
                          )}
                        </div>

                        {/* <div className="mb-3">
                          <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={handleCaptchaChange}
                          />
                          {errors.captcha && (
                            <div className="invalid-feedback">{errors.captcha}</div>
                          )}
                        </div> */}
                        <hr className="my-4" />
                        <div className="d-grid mt-4">
                          <button type="submit" className="sign_in_page_1">
                            Sign in
                          </button>
                        </div>
                      </form>
                      <div className="mt-4 text-start">
                        {/* <p>
                          By signing up, you agree to our{" "}
                          <a href="#terms">Terms Of Service</a> and{" "}
                          <a href="#privacy">Privacy Policy</a>
                        </p> */}
                        <p>
                          Already have an account?
                          <Link to="/userlogin">
                            <button className="Register_1">Login</button>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
