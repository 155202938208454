// Import necessary libraries and styles
import React, { useState, useEffect } from "react";

import "../../../assets/css/home/initial.css";
import { Carousel } from "react-bootstrap";
// import Celebration from "../../../assets/img/home13.svg";
// import Celebration2 from "../../../assets/img/home_bg1.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

function Section_initial() {

  if (typeof process === 'undefined') {
    window.process = { env: {} };
  }
  
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get("https://backoffice.innerpece.com/api/slider")
      .then(response => {
        setImages(response.data.sliders || []);
      })
      .catch(error => {
        console.error("Error fetching images:", error);
      });
  }, []);


  return (
    <section className="carousel-wrapper">
      <Carousel controls={true} indicators={true} interval={3000} fade={false}>
        {images.map((image, index) => (
          <Carousel.Item key={index} className="drk">
            <img
              src={`https://backoffice.innerpece.com/${image.slider_image}`} 
              alt={`Slide ${index + 1}`} 
              className="d-block w-100" 
            />
            <Carousel.Caption className="carousel-content">
              <h3>{image.slider_name}</h3> {/* Dynamic Title */}
              <p>{image.subtitle}</p> 
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
{/* 
      <div className="fixed-content">
        <div className="container mt-5">
          <div className="search-bar" id="search-bar12">
            <div className="searcgh_borders1 p-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-transparent">
                    <i className="bi bi-compass icons_12"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Where to?"
                  className="form-control border-0"
                />
              </div>
            </div>
            <div className="p-2 searcgh_borders1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-transparent">
                    <i className="bi bi-calendar icons_12"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Select Month"
                  className="form-control border-0"
                />
              </div>
            </div>
            <div className="p-2 searcgh_borders1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 bg-transparent">
                    <i className="bi bi-geo-alt-fill icons_12"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Select Type"
                  className="form-control border-0"
                />
              </div>
            </div>
            <div className="findbtrn">
              <button className="btn btn-search search_find_btrn">Find Now</button>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default Section_initial;
