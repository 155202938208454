import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// import l1 from "../../../assets/svg/l1.svg";
// import l2 from "../../../assets/svg/l2.svg";
// import l3 from "../../../assets/svg/l3.svg";
// import l4 from "../../../assets/svg/l4.svg";

// import l5 from "../../../assets/svg/l5.svg";
// import l6 from "../../../assets/svg/l6.svg";
// import l7 from "../../../assets/svg/l7.svg";
// import l8 from "../../../assets/svg/l8.svg";
import { Link } from "react-router-dom";
import map_img from "../../../assets/svg/map_img.svg";
import cal_img from "../../../assets/svg/cal_img.svg";

const Section_3 = () => {
  const [programData, setProgramData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate hook

  const handleClick = (id, title) => {
    const formattedThemeName = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-") // Remove all special characters and replace with hyphen
      .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ""); // Trim hyphens from the start and end

    // Perform navigation
    navigate(`/${id}/${formattedThemeName}`, { state: { id, title } }); // Adjust URL based on your route configuration
  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchProgramData = async () => {
      try {
        const response = await axios.post(
          "https://backoffice.innerpece.com/api/get-program",

          {
            program_type: "upcoming_program",
            view_type: "",
          }
        );
        console.log("check", response);
        setProgramData(response.data.data);
        // console.log("fetch", response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProgramData();
  }, []);

  return (
    <div className="container-fluid paddings mt-4">
      <div className="d-flex justify-content-between">
        <div className="col-12">
          <h1 className="mb-5 loc_title">
            Explore{" "}
            <span className="Upcoming_Explore123">Upcoming Programs</span>
          </h1>
        </div>
        <div className="col-3 text-end mt-4 ">
          <h5 className="view_all71 viewallpage">
            <a href="/upcomingevents">View All</a>
          </h5>
        </div>
      </div>

      <div className="row mb-2">
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}

        {programData.length > 0 ? (
          programData.map((event, index) => (
            <div key={index} className="col-lg-3 col-md-6 mb-4 mmb-30">
              <div
                className="card shadow-lg"
                onClick={() => handleClick(event.id, event.title)} // Call handleClick on click
                style={{ cursor: "pointer" }} // Change cursor to pointer
              >
                <img
                  className="card-img-top c_img1"
                  src={`https://backoffice.innerpece.com/${event.cover_img}`}
                  alt="Card"
                />
                <div className="card-body">
                  <h4 className=" c1 ms-2">{event.title}</h4>
                  <div className=" c2">
                    <img className="map_img ms-1" src={map_img} alt="Card" />
                    <span className="p-1">{event.location}</span>
                  </div>
                  <hr style={{ width: "50%" }} />
                  <div className="card-text c2">
                    <img className="cal_img ms-2" src={cal_img} alt="Card" />
                    <span className="p-1 ms-1">{event.start_date}</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No programs available.</p>
        )}
      </div>

      {/*        
      <div className="row mt-5 mb-5 ">
    
        {cardData.map((card, index) => (
          <div key={index} className="col-md-3">
            <Link style={{ textDecoration: "none" }} to={"upcomingevents"}>
              <div className="card">
                
                <img
                  className="card-img-top c_img"
                  src={imagePaths2[index % imagePaths2.length]}
                  alt="Card"
                />
                <div className="card-body">
                  <h4 className="card-title c1 ms-2">{card.title}</h4>
                  <div className="card-text c2 ">
                    {" "}
                    <img className="map_img ms-1" src={map_img} alt="Card" />
                    <span className="p-1">{card.text}</span>
                  </div>
                  <hr style={{width:'50%'}}></hr>
                  <div className="card-text c2 ">
                    {" "}
                    <img className="map_img ms-2" src={cal_img} alt="Card" />
                    <span className="p-1">{card.text2}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>  */}

      <div className="container-fluid ">
        <div className="row do_color">
          <div className="col-md-8 col-12 text-center text-md-left">
            <h1 className="do_text_1 ms-4">
              Do you want to experience an event with us?
            </h1>
            <h1 className="do_text_2 ms-4">Don't wait any longer</h1>
          </div>

          <div className="col-md-4 col-12 text-center text-md-right mt-3 mt-md-0   do_btn">
            <Link to="/contact">
              <p className="btn btn-white talk_btn p-2">Talk to us</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_3;
