// Import necessary modules and styles
import React, { useEffect, useState } from "react";
import "../../../assets/css/Contact_us/Contact_us.css";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

function Contact() {
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // const [Token, setCsrfToken] = useState('');
  const [formStatus, setFormStatus] = useState("");
  const [faqs, setFaqs] = useState([]);

  const [errors, setErrors] = useState({});
  const [footercontent, setfootercontent] = useState(null);

  const validate = () => {
    const newErrors = {};
    if (!first_name) newErrors.first_name = "First name is required.";
    if (!last_name) newErrors.last_name = "Last name is required.";
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid.";
    }
    if (!phone) newErrors.phone = "Phone number is required.";
    if (!message) newErrors.message = "Message is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setErrors({});
    setFormStatus("");

    try {
      const response = await axios.post(
        "https://backoffice.innerpece.com/api/contact",
        {
          first_name,
          last_name,
          email,
          phone,
          message,
        }
      );
      setFormStatus("Success: " + response.data.message);

      // Clear the form fields
      setFirst_name("");
      setLast_name("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setFormStatus(
        "Error: " +
          (error.response?.data?.message || "An unexpected error occurred.")
      );
    }
  };

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(
          "https://backoffice.innerpece.com/api/csrf-token"
        );
        // setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();

    axios
      .get("https://backoffice.innerpece.com/api/faq")
      .then((response) => {
        if (response.data && Array.isArray(response.data.faqs)) {
          setFaqs(response.data.faqs);
        } else {
          console.error("Unexpected API response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the FAQs!", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://backoffice.innerpece.com/api/footer-content")
      .then((response) => {
        setfootercontent(response.data);
        // console.log("response",response.data)
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  return (
    <>
      <div className="App">
        <header className="contact-header1">
          <div className="container text-center">
            <div className="Contact_us12345">
              <h1>Contact Us</h1>
              <p>
                <a href="/">Home</a> / Contact Us
              </p>
            </div>
          </div>
        </header>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="Contact_us123 mfs-26">Get in Touch with Our Team</h2>
            <p className="text-muted mt-5">
              Connect with our team at Innerpece for all your travel needs and
              inquiries. We're here to help!
            </p>
            {footercontent && (
              <div className="row d-flex">
                <div className="col-md-5">
                  <h5 className="mb-4 phone_us">PHONE</h5>
                  <p>
                    <a
                      href="tel:91750214903"
                      style={{ textDecoration: "none" }}
                      className="Contact_us1234"
                    >
                      <i className="bi bi-telephone"></i>{" "}
                      {footercontent.contact_number}
                    </a>
                  </p>
                </div>
                <div className="col-md-7">
                  <h5 className="mb-4 phone_us">EMAIL</h5>
                  <p>
                    <a
                      href="mailto:jay@innerpeace.com"
                      style={{ textDecoration: "none" }}
                      className="Contact_us1234"
                    >
                      <i className="bi bi-envelope"></i>{" "}
                      {footercontent.contact_email}
                    </a>
                    <br />
                    <a
                      href="mailto:jay@innerpeace.com"
                      style={{ textDecoration: "none" }}
                      className="Contact_us1234"
                    >
                      <i className="bi bi-envelope"></i> bharath@innerpeace.com
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-6 mp-20">
            <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="first_name" className="form-label Form_us">
                    Your First Name
                  </label>
                  <input
                    type="text"
                    className={`form-control mt-2 custom-input ${
                      errors.first_name ? "is-invalid" : "form_12345"
                    }`}
                    name="first_name"
                    onChange={(e) => setFirst_name(e.target.value)}
                    placeholder="Enter your first name"
                    value={first_name}
                  />
                  {errors.first_name && (
                    <div className="invalid-feedback">{errors.first_name}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="last_name" className="form-label Form_us">
                    Your Last Name
                  </label>
                  <input
                    type="text"
                    className={`form-control mt-2 custom-input ${
                      errors.last_name ? "is-invalid" : "form_12345"
                    }`}
                    name="last_name"
                    onChange={(e) => setLast_name(e.target.value)}
                    placeholder="Enter your last name"
                    value={last_name}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name}</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="email" className="form-label Form_us">
                    Your Email
                  </label>
                  <input
                    type="email"
                    className={`form-control mt-2 custom-input ${
                      errors.email ? "is-invalid" : "form_12345"
                    }`}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    value={email}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="phone" className="form-label Form_us">
                    Your Phone
                  </label>
                  <input
                    type="text"
                    className={`form-control mt-2 custom-input ${
                      errors.phone ? "is-invalid" : "form_12345"
                    }`}
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter your phone"
                    value={phone}
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">{errors.phone}</div>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label Form_us">
                  Your Message
                </label>
                <textarea
                  className={`form-control mt-2 custom-input ${
                    errors.message ? "is-invalid" : "form_12345"
                  }`}
                  name="message"
                  rows="4"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message"
                  value={message}
                ></textarea>
                {errors.message && (
                  <div className="invalid-feedback">{errors.message}</div>
                )}
              </div>
              <button type="submit" className="btn Contact_us123456">
                Send Message
              </button>
            </form>
            {formStatus && (
              <p
                className={
                  formStatus.startsWith("Success")
                    ? "form-status-success"
                    : "form-status-error"
                }
              >
                {formStatus}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="Visitour">
        <Container className="my-5">
          <Row className="align-items-center">
            <Col md={6}>
              <h2 className="Contact_us123 mt-5 mfs-26">Visit Our Office</h2>
              <p className="text-muted mt-4">
                Visit our office at Innerpece! We're here to discuss your travel
                plans and get expert help from our friendly team.
              </p>
              <a
                href="https://www.google.com/maps/place/Mgr/@10.96019,79.381923,16z/data=!4m6!3m5!1s0x3a553340
             ddc52b29:0x2f4c234a3e71b44!8m2!3d10.9601852!4d79.3844976!16s%2Fg%2F11rc49m0z9?hl=en&entry=ttu&g_ep=EgoyMDI0MDkwMi4xIKXMDSoASAFQAw%3D%3D"
                target="blank"
              >
                <button className="btn Contact_us123456 mb-5">
                  Get Direction
                </button>
              </a>
            </Col>
            <Col md={6}>
              <iframe
                title="office-location"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3917.042127194694!2d79.38192267573746!3d10.960190455775871!2m3!1f0!2f0!3f0
                !3m2!1i1024!2i768!4f13.1!2m1!1s35-C%2C%20Sevaliye%20Sivaji%20Nagar%2C%20Darasuram%2C%20Kumbakonam!5e0!3m2!1sen!2sin!4v1725463089931!5m2!1sen!2sin"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="container my-5">
        <h2 className="Contact_us123 mfs-26">Frequently Asked Questions</h2>
        <div className="accordion mt-4" id="faqAccordion">
          {faqs.map((faq, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="false"
                  aria-controls={`collapse${index}`}
                >
                  {faq.question}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Contact;
