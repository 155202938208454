import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../../assets/css/Events/Upcomingevents.css";
import Location from "../../../assets/img/Events/location.img.svg";
import Booking from "../../../assets/img/Events/bicycle_img.svg";
import data from "../../../assets/img/Events/calendar_img.svg";
import Guests from "../../../assets/img/Events/user.img.svg";

import Round from "../../../assets/img/Events/round_img.svg";
import Round1 from "../../../assets/img/Events/round_img1.svg";
import Person from "../../../assets/img/Events/person_img.svg";
import Days from "../../../assets/img/Events/days_img.svg";
import Upcomingevent1 from "../../../assets/img/Events/upcomingevent_1.svg";
import Upcomingevent2 from "../../../assets/img/Events/upcomingevent_2.svg";
import Upcomingevent3 from "../../../assets/img/Events/upcomingevent_3.svg";
import Upcomingevent4 from "../../../assets/img/Events/upcomingevent_4.svg";
import Upcomingevent5 from "../../../assets/img/Events/upcomingevent_5.svg";
// import Search from "../../../assets/img/Events/search.svg";

// import { Modal, Button } from "react-bootstrap";
function PopularEvents() {
  const [minPrice, setMinPrice] = useState(3000);
  const [maxPrice, setMaxPrice] = useState(20000);
  // const [showModal, setShowModal] = useState(false);

  const handleRangeChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (event.target.id === "minRange") {
      setMinPrice(value);
    } else {
      setMaxPrice(value);
    }
  };
   const handleFilterClick = () => {
    // Implement the filter logic here based on minPrice and maxPrice
    console.log(`Filtering products between ₹${minPrice} and ₹${maxPrice}`);
  };

  const events = [
    {
      id: 1,
      location: "Jawadhu Hills, Tamilnadu.",
      image: Upcomingevent1,
      title: "Essence of Vietnam South to North",
      reviews: 1,
      days: 5,
      people: 12,
      price: 2999,
      originalPrice: 3999,
    },
    {
      id: 2,
      location: "Yercaud, Tamilnadu.",
      image: Upcomingevent2,
      title: "Essence of Vietnam South to North",
      reviews: 1,
      days: 5,
      people: 12,
      price: 2999,
      originalPrice: 3999,
    },
    {
      id: 3,
      location: "Guduvancheri, Tamilnadu.",
      image: Upcomingevent3,
      title: "Essence of Vietnam South to North",
      reviews: 1,
      days: 5,
      people: 12,
      price: 2999,
      originalPrice: 3999,
    },
    {
      id: 4,
      location: "Yelagiri, Tamilnadu.",
      image: Upcomingevent4,
      title: "Essence of Vietnam South to North",
      reviews: 1,
      days: 5,
      people: 12,
      price: 2999,
      originalPrice: 3999,
    },
    {
      id: 5,
      location: "Kollidam, Tamilnadu.",
      image: Upcomingevent5,
      title: "Essence of Vietnam South to North",
      reviews: 1,
      days: 5,
      people: 12,
      price: 2999,
      originalPrice: 3999,
    },
  ];

  document.addEventListener("DOMContentLoaded", () => {
    const ratingStars = document.querySelectorAll(
      '.star-rating1 input[type="radio"]'
    );
    ratingStars.forEach((star) => {
      star.addEventListener("change", (event) => {
        console.log(`Rated ${event.target.value} stars`);
      });
    });
  });

  const [selectedValue, setSelectedValue] = useState("1");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleFilterChange = (filterType, value) => {
    console.log(`Filter changed: ${filterType} = ${value}`);
  };
  return (
    <>
      <div className="section2 mt-3">
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="WesternSidebar1">
                <div className="Western-Navigation-bar1">
                  <p className="hmmtr">Home &gt; Explore Popular Events</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="titleBack img-fluid p-4">
                  <div className="banner text-center1">
                    <div><h5 className="banner-title1">Explore Popular Events</h5></div>
                    <h6 className="banner-desc1 mt-2">
                      Lorem ipsum dolor sit amet consectetur. Sed egestas mauris
                      ornare amet egestas.
                    </h6>
                    <div className="inputContainer justify-content-center align-items-center mt-4">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text border-0 bg-transparent">
                            <img src={Search} alt="Search Icon" />
                          </span>
                        </div>
                        <input
                          type="text"
                          placeholder="Search Events"
                          className="form-control border-0"
                        />
                      </div>
                      <button className="btn bbttnn">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container-fluid">
          <div className="row totalpages">
            <div className="col-lg-3 sidebarr ">
              <p className="serchfilters"> Search By Filter</p>

              <div
                className="p-5 border rounded d-grid"
                style={{ fontWeight: "600" }}
              >
                <div className=" gap-5 d-grid"></div>
                <div className="row border p-2 ps-2 rounded align-items-center">
                  <div className="col-2">
                    <img src={Location} alt="Location img" />
                  </div>

                  <div className="col-10">
                    <select
                      className="col-12 border-0 p-1 fontcolor"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <option value="1">Location</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="row border p-2 ps-2 rounded align-items-center mt-3">
                  <div className="col-2">
                    <img src={Booking} alt="Booking img" />
                  </div>

                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Booking Type</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="row border p-2 ps-2 rounded align-items-center mt-3">
                  <div className="col-2">
                    <img src={data} alt="Data img" />
                  </div>

                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Date Form</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="row border p-2 ps-2 rounded align-items-center mt-3">
                  <div className="col-2">
                    <img src={Guests} alt="Guests img" />
                  </div>

                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Guests </option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="filter-container55 mt-3">
      <h6 className="subtitle p2">Filter by Price</h6>
      <div className="range-inputs">
        <input
          type="range"
          className="form-range"
          id="minRange"
          min="3000"
          max="20000"
          step="100"
          value={minPrice}
          onChange={handleRangeChange}
        />
        <input
          type="range"
          className="form-range"
          id="maxRange"
          min="3000"
          max="20000"
          step="100"
          value={maxPrice}
          onChange={handleRangeChange}
        />
      </div>
      <p>
        Price: &#8377;{minPrice} - &#8377;{maxPrice}
      </p>
      <div className="ttrre">
        <input
          className="btn fill"
          type="button"
          value="FILTER"
          onClick={handleFilterClick}
        />
      </div>
    </div>
                <div className="row border p-2 ps-2 rounded mt-3">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Language</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="row border p-2 ps-2 rounded mt-3">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Month</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="row border p-2 ps-2 rounded mt-3">
                  <select className="col-12 border-0 p-1 fontcolor">
                    <option value="1">Duration</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="p-3 border rounded d-none d-lg-block adfetures">
                <h6 className="subtitle ms-3 p1">Additional Features</h6>
                {["10+", "12+", "15+", "Adults"].map((activity, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start my-2 ms-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input border-checkbox"
                      onClick={() => handleFilterChange("activity", activity)}
                    />
                    <div
                      className="px-2"
                      onClick={() => handleFilterChange("activity", activity)}
                    >
                      {activity}
                    </div>
                  </div>
                ))}
              </div>

              <div className="p-3 border rounded d-none d-lg-block sdadded">
                <h6 className="subtitle ms-3 p1">Additional Features</h6>
                {[
                  "Accepts Credit Card",
                  "Car Parking",
                  "Free Coupons",
                  "Laundry Service",
                  "Outdoor Seating",
                  "Reservation",
                  "Restaurant",
                  "Smoking Allowed",
                ].map((activity, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-start my-2 ms-3"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input border-checkbox"
                      onClick={() => handleFilterChange("activity", activity)}
                    />
                    <div
                      className="px-2"
                      onClick={() => handleFilterChange("activity", activity)}
                    >
                      {activity}
                    </div>
                  </div>
                ))}
              </div>
              {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Sort by Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="p-5 border d-grid gap-3 rounded"
                    style={{ fontWeight: "600" }}
                  >
                    <div className="row border p-2 ps-2 rounded align-items-center">
                    <div className="col-2">
                    <img src={Location} alt="Location img" />
                  </div>

                  <div className="col-10">
                    <select
                      className="col-12 border-0 p-1 fontcolor"
                      value={selectedValue}
                      onChange={handleSelectChange}
                    >
                      <option value="1">Location</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                    </div>
                    <div className="row border p-2 ps-2 rounded align-items-center">
                    <div className="col-2">
                    <img src={Booking} alt="Booking img" />
                  </div>

                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Booking Type</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                    </div>
                    <div className="row border p-2 ps-2 rounded align-items-center">
                    <div className="col-2">
                    <img src={data} alt="Data img" />
                  </div>

                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Date Form</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                    </div>
                    <div className="row border p-2 ps-2 rounded align-items-center">
                    <div className="col-2">
                    <img src={Guests} alt="Guests img" />
                  </div>

                  <div className="col-10">
                    <select className="col-12 border-0 p-1 fontcolor">
                      <option value="1">Guests </option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                    </div>
                    <div className="filter-container">
                      <h6 className="subtitle p2">Filter by Price</h6>
                      <div className="range-inputs">
                        <input
                          type="range"
                          className="form-range"
                          id="minRange"
                          min="3000"
                          max="20000"
                          step="100"
                          value={minPrice}
                          onChange={handleRangeChange}
                        />
                        <input
                          type="range"
                          className="form-range"
                          id="maxRange"
                          min="3000"
                          max="20000"
                          step="100"
                          value={maxPrice}
                          onChange={handleRangeChange}
                        />
                      </div>
                      <p>
                        Price: &#8377;{minPrice} - &#8377;{maxPrice}
                      </p>
                      <div>
                        <input
                          className="btn btn-primary p-2 ms-3 fill"
                          type="button"
                          value="FILTER"
                        />
                      </div>
                    </div>
                    <div className="row border p-2 ps-2 rounded">
                      <select className="col-12 border-0 p-1 fontcolor">
                        <option value="1">Language</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="row border p-2 ps-2 rounded">
                      <select className="col-12 border-0 p-1 fontcolor">
                        <option value="1">Month</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="row border p-2 ps-2 rounded">
                      <select className="col-12 border-0 p-1 fontcolor">
                        <option value="1">Duration</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>

                  <div className="p-3 border rounded">
                    <h6 className="subtitle ms-3 p1">Additional Features</h6>
                    {["10+", "12+", "15+", "Adults"].map((activity, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-start my-2 ms-3"
                      >
                        <input
                          type="checkbox"
                          className="form-check-input border-checkbox"
                          onClick={() =>
                            handleFilterChange("activity", activity)
                          }
                        />
                        <div
                          className="px-2"
                          onClick={() =>
                            handleFilterChange("activity", activity)
                          }
                        >
                          {activity}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="p-3 border rounded">
                    <h6 className="subtitle ms-3 p1">Additional Features</h6>
                    {[
                      "Accepts Credit Card",
                      "Car Parking",
                      "Free Coupons",
                      "Laundry Service",
                      "Outdoor Seating",
                      "Reservation",
                      "Restaurant",
                      "Smoking Allowed",
                    ].map((activity, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-start my-2 ms-3"
                      >
                        <input
                          type="checkbox"
                          className="form-check-input border-checkbox"
                          onClick={() =>
                            handleFilterChange("activity", activity)
                          }
                        />
                        <div
                          className="px-2"
                          onClick={() =>
                            handleFilterChange("activity", activity)
                          }
                        >
                          {activity}
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal> */}
            </div>
           
            <div className="col-lg-9 col-md-12 col-sm-12 p-5 d-grid gap-3">
            <div className="Events_90 mb-5">
            <h1>Events</h1>
            </div>
              <div className="d-flex justify-content-between border p-2 rounded mtpar">
               
                <div>
                  <h3 className="page-title mt-2">Showing 12 of 21 Results</h3>
                  </div>
                <div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Sort By: Most Loved </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              {events.map((event, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between row rounded mb-4 sharder"
                >
                  <div
                    className="card col-lg-4 col-md-12 col-sm-1 d-flex justify-content-center align-items-center"
                    id="popular_145"
                  >
                    <img
                      src={event.image}
                      alt="Location img"
                      className="full-size-img "
                    />
                    <p className="ftrr">Featured</p>
                  </div>
                  <div className="col-lg-8 col-12 col-md-12 p-3">
                    <div className="row d-flex">
                      <div className="col-lg-8 col-sm-6 d-flex order-sm-1">
                        <div className="me-3">
                          <img
                            src={Location}
                            alt="Location img"
                            className="location-img img-fluid"
                            id="lls"
                          />
                        </div>
                        <p>{event.location}</p>
                      </div>
                      <div className="col-2 col-lg-1 col-sm-2 order-sm-2 imggscol">
                        <img
                          src={Round}
                          alt="Location img"
                          className="rounded-circle "
                          id="lls"
                        />
                      </div>
                      <div className="col-2 col-lg-1 col-sm-2 order-sm-2 imggscol">
                        <img
                          src={Round1}
                          alt="Location img"
                          className="rounded-circle "
                          id="lls"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 Fontevent">
                        <h2 className="titletx">{event.title}</h2>
                      </div>
                    </div>
                    <div className="row strrors">
                      <div className="col-4">
                        <div className="star-rating1">
                          <input
                            type="radio"
                            id={`star5-${index}`}
                            name={`rating-${index}`}
                            value="5"
                          />
                          <label htmlFor={`star5-${index}`} title="5 stars">
                            &#9733;
                          </label>
                          <input
                            type="radio"
                            id={`star4-${index}`}
                            name={`rating-${index}`}
                            value="4"
                          />
                          <label htmlFor={`star4-${index}`} title="4 stars">
                            &#9733;
                          </label>
                          <input
                            type="radio"
                            id={`star3-${index}`}
                            name={`rating-${index}`}
                            value="3"
                          />
                          <label htmlFor={`star3-${index}`} title="3 stars">
                            &#9733;
                          </label>
                          <input
                            type="radio"
                            id={`star2-${index}`}
                            name={`rating-${index}`}
                            value="2"
                          />
                          <label htmlFor={`star2-${index}`} title="2 stars">
                            &#9733;
                          </label>
                          <input
                            type="radio"
                            id={`star1-${index}`}
                            name={`rating-${index}`}
                            value="1"
                          />
                          <label htmlFor={`star1-${index}`} title="1 star">
                            &#9733;
                          </label>
                        </div>
                      </div>
                      <div className="col-8 mt-2">
                        <p className="reviewevent">(1 Review)</p>
                      </div>

                      {/* Line break on small screens */}
                    </div>

                    <div className="row align-items-center strrors">
                      <div className="col-lg-3 col-md-3 col-sm-6 d-flex align-items-center">
                        <img src={Days} alt="Days img" className=" me-1" />
                        <div className="">
                          <p className="reviewevent1 mt-3">5 Days</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 ms-4 d-flex align-items-center">
                        <img src={Person} alt="Days img" className="me-1" />
                        <div>
                          <p className="reviewevent1 mt-3">12 Person</p>
                        </div>
                      </div>
                      {/* <div className="col-lg-2 col-md-6 col-sm-6 d-flex align-items-center">
                          <img
                            src={Person}
                            alt="Person img"
                            className="rounded-circle me-3"
                          />
                          <div className="  reviewevent1 ">12 Person</div>
                        </div> */}
                    </div>
                    <hr className="light-line popular_1" />
                    <div className="row d-flex mb-3  popular_1">
                      <div className="col-lg-6 col-md-6 col-sm-2 d-flex">
                        <span className="from-text ">From</span>
                        <span className="price-text23 ms-2 ">&#8377;2,999</span>
                        <s className="ms-3">&#8377;3999</s>

                        <div className="col-lg-6 col-md-6 col-sm-12 linkButton45">
                          <Link style={{ textDecoration: "none" }} to="/sample">
                            <a
                              href="#"
                              className="btn linkButton1"
                              tabindex="-1"
                              role="button"
                            >
                              View <i className="fas fa-arrow-right"></i>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <nav
            aria-label="Page navigation example"
            className="page_nation gap-4"
          >
            <ul className="pagination rounded-pagination gap-4">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true" className="arraw">
                    &laquo;
                  </span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default PopularEvents;
