import React, { useEffect, useState } from 'react';
import "../../../assets/css/Podcast/Podcast.css";
import axios from 'axios';

// If the podcast link is a YouTube URL, extract the video ID and return the thumbnail URL.
const getThumbnail = (url) => {
  try {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get('v');
    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    }
    const pathParts = urlObj.pathname.split('/');
    return `https://img.youtube.com/vi/${pathParts[pathParts.length - 1]}/0.jpg`;
  } catch (error) {
    console.error('Invalid URL:', url);
    return 'default-thumbnail.jpg'; // Fallback image if URL is invalid
  }
};

const PodcastGallery = () => {
  const [podcasts, setPodcasts] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://backoffice.innerpece.com/api/podcast')
      .then(response => {
        if (Array.isArray(response.data.data)) {
          setPodcasts(response.data.data);
          // console.log("response",response.data.data)
        } else {
          setError('Unexpected response format');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
        setError('Failed to load podcasts. Please try again later.');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container p-5 mt-5" >
      <h1 className="text-center mb-4 Podcasts1">PODCASTS</h1>
      <div className="row " style={{marginTop:'200px'}}>
        {podcasts.length === 0 ? (
          <p>No podcasts available at the moment.</p>
        ) : (
          podcasts.map((video, index) => (
            <div key={index} className="col-lg-4 col-md-6 mb-4">
              <div className="card Podcast2">
                <a href={video.podcast_link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={getThumbnail(video.podcast_link)}
                    className="card-img-top Podcasts3 full-thumbnail"
                    alt="Video Thumbnail"
                  />
                  <div className="video-play-button">
                    <i className="bi bi-youtube"></i>
                  </div>
                </a>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PodcastGallery;
