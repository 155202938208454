import React from "react";
import { useEffect } from "react";
import "../../../assets/css/About_us/Aboutus.css";
import AboutUs1 from "../../../assets/img/About_us/about_us1.svg";
import AboutUs2 from "../../../assets/img/About_us/about_us2.svg";
import AboutUs3 from "../../../assets/img/About_us/about_us3.svg";
import AboutUs4 from "../../../assets/img/About_us/about_us4.svg";
// import AboutUs5 from "../../../assets/img/About_us/about_us5.svg";
import AboutUs6 from "../../../assets/img/About_us/about_us6.svg";
import AboutUs7 from "../../../assets/img/About_us/about_us7.svg";
import AboutUs8 from "../../../assets/img/About_us/about_us8.svg";
import AboutUs9 from "../../../assets/img/About_us/about_us9.svg";
import AboutUs10 from "../../../assets/img/About_us/about_us10.svg";
import AboutUs11 from "../../../assets/img/About_us/about_us11.svg";
import AboutUs12 from "../../../assets/img/About_us/about_us12.svg";
import { Link } from "react-router-dom";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sections = [
    {
      title: "Tellus est et",
      imageUrl: AboutUs9,
      description:
        "Nisi venenatis eu nunc sem. Turpis aenean tortor rhoncus eget quam laoreet turpis viverra justo. Massa dignissim enim diam in. Diam imperdiet ornare elit rhoncus diam gravida pharetra. Turpis integer lectus et.",
      learnMoreLink: "#",
    },
    {
      title: "Turpis non lorem",
      imageUrl: AboutUs10,
      description:
        "At integer nibh lacus tortor. Aenean enim mi ipsum aenean adipiscing eu faucibus. Nibh pharetra nulla arcu faucibus proin elit. Ante convallis felis scelerisque et arcu blandit. Elementum elit viverra cras blandit convallis.",
      learnMoreLink: "#",
    },
    {
      title: "Enim sit dignissim",
      imageUrl: AboutUs11,
      description:
        "Eget convallis mattis vel ligula amet nulla lacinia quam malesuada. A pharetra massa pulvinar nulla. Eget nec nunc tristique sed non gravida. Ut auctor tellus suscipit ultrices est pretium. Mus odio et turpis lacinia sit.",
      learnMoreLink: "#",
    },
  ];

  return (
    <>
      <div className="section4">
        <div className="container-fluid about-us-section">
          <h2 className="About_heading text-center">About Us</h2>
          <div className="row Aboutpage_123">
            <div className="col-lg-6 col-md-12 left-content">
              <div className="row g-3">
                <div className="col-12 col-lg-6 col-sm-6">
                  <img
                    src={AboutUs1}
                    alt="Image 1"
                    className="img-fluid rounded c_img"
                  />
                </div>
                <div className="col-12 col-lg-6 col-sm-6">
                  <div className="row g-3">
                    <div className="col-12">
                      <img
                        src={AboutUs2}
                        alt="Image 2"
                        className="img-fluid rounded c_img"
                      />
                    </div>
                    <div className="col-12 mt-3 mt-lg-0">
                      <img
                        src={AboutUs3}
                        alt="Image 3"
                        className="img-fluid rounded c_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 right-content mt-md-0">
              {/* <p className="About_heading3">About Us</p> */}
              {/* <h1 className="About_heading1">Tincidunt eu aliquam</h1> */}
              {/* <h4 className="About_heading2">Our Vision</h4> */}
              <p className="About_heading4 responsive-paragraph paras mt-5">
                We are excited to introduce Innerpece, a platform dedicated to
                providing meaningful travel experiences that focus on nurturing
                mental well-being and personal growth. Our carefully curated
                travel programs offer a much-needed mental break while allowing
                you to connect with innovative entrepreneurs and inspiring
                creators. Our main objective is to assist you in finding deeper
                happiness and inner pece through these experiences.
              </p>
              <div className="mt-4">
                <p className="About_heading4 responsive-paragraph paras mt-4">
                  In addition, we understand the importance of family time and
                  offer tailored travel programs designed for families. Explore
                  the beauty and cultural richness of India with our private
                  itineraries, aimed at creating unforgettable moments and
                  strengthening family bonds.
                </p>
              </div>
              <div>
                <p className="About_heading4 responsive-paragraph paras mt-4">
                  At Innerpece, we are deeply committed to the transformative
                  power of travel. Let us guide you on a journey that not only
                  refreshes your mind but also enriches your life. Discover how
                  our unique travel experiences can lead you to a path of
                  greater fulfillment and connection.
                </p>
              </div>
            </div>
          </div>
          <div className="row  col-md-12 col-sm-12 About_heading5 Aboutpage_123">
            <div className="col-lg-6 col-md-12 col-sm-8">
              <div className="">
                {/* <h6 className="text-uppercase mt-3">Why Choose Innerpece?</h6> */}
                <h1 className="display-5 fw-bold">Why Choose Innerpece?</h1>
              </div>
              <div className="mb-4 col-12">
                <h2 className="h4 About_letter col-12 mt-5">
                  <span className=" me-5">01</span>Seamless Trip Planning
                </h2>
                <p className=" About_heading10 paras">
                  Innerpece handles all aspects of your weekend getaway, from
                  logistics to activities, ensuring a well-organized and
                  effortless experience.
                </p>
              </div>
              <hr />
              <div className="mb-4 col-12">
                <h2 className="h4 About_letter col-12">
                  <span className="me-5">02</span> High-Quality Food
                </h2>
                <p className=" About_heading10 paras">
                  Enjoy a variety of gourmet meals during your stay, each
                  prepared with care to enhance your overall getaway experience.
                </p>
              </div>
              <hr />
              <div className="mb-4 col-12">
                <h2 className="h4 About_letter col-12">
                  <span className="me-5">03</span> Personalized Service
                </h2>
                <p className=" About_heading10 paras">
                  Benefit from attentive, individualized support throughout your
                  trip, ensuring all your needs are met for a memorable and
                  enjoyable escape.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ss ">
              <div className="container ">
                <img
                  src={AboutUs4}
                  alt="Small placeholder"
                  className="About_heading7 img-fluid c_img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid About_heading8 text-center text-white py-5">
          <h5 className="mb-4">OUR STORY</h5>
          <div className="row">
            {/* <div className="About_heading9 col-4 "></div> */}
            <div className="col-12 align-content-center justify-content-center">
              <h1 className="mb-5 About_heading11">
                Vel malesuada odio id lectus viverra
              </h1>
            </div>
          </div>
          <div className="row justify-content-center g-5 align-items-center">
            <div className="col-md-4">
              <div className="card bg-dark text-white">
                <img
                  src={AboutUs6}
                  className="card-img img-fluid c_img"
                  alt="Founding Vision"
                />
                <div className="card-img-overlay d-flex  p-3">
                  <h5 className="card-title">Founding Vision</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-dark text-white">
                <img
                  src={AboutUs7}
                  className="card-img img-fluid c_img"
                  alt="Journey of Growth"
                />
                <div className="card-img-overlay d-flex  p-3">
                  <h5 className="card-title">Journey of Growth</h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-dark text-white">
                <img
                  src={AboutUs8}
                  className="card-img img-fluid c_img"
                  alt="Adaptation to Change"
                />
                <div className="card-img-overlay d-flex  p-3">
                  <h5 className="card-title">Adaptation to Change</h5>
                </div>
              </div>
            </div>
          </div>
          <p className="mt-5 paras">
            Velit ultrices sed lobortis tristique. Quam cras sollicitudin cum
            amet eget praesent. Semper mollis posuere tincidunt lorem sit
            adipiscing tincidunt ut amet. Malesuada tincidunt risus in aliquet
            platea sapien. Netus amet.
          </p>
          <Link to="/contact">
            <button className="btn btn-light Get_start_123 mt-4">
              Get Started
            </button>
          </Link>
        </div>
        <div className="container my-5">
          <div className="text-center mb-5">
            <h4>OUR VALUE</h4>
            <h1>Ac gravida at arcu vitae ut nulla. Sed</h1>
          </div>
          {sections.map((section, index) => (
            <div key={index} className="row mb-5">
              <div className="col-12 col-md-4 mb-3 mb-md-0">
                <h5 className="fw-bold ms-4 About_heading11 text-center">
                  {section.title}
                </h5>
              </div>
              <div className="col-12 col-md-4 text-center mb-3 mb-md-0">
                <img
                  src={section.imageUrl}
                  alt={section.title}
                  className="img-fluid rounded c_img"
                />
              </div>
              <div className="col-12 col-md-4">
                <p className="paras">{section.description}</p>
                <a
                  href={section.learnMoreLink}
                  className="text-decoration-none"
                >
                  Learn More
                </a>
              </div>
              <hr className="mt-5" />
            </div>
          ))}
        </div>
        <div className="container-fluid">
          <img
            src={AboutUs12}
            alt="Small placeholder"
            className="img-fluid w-100 c_img group_img_12"
          />
        </div>
      </div>
    </>
  );
}

export default About;
